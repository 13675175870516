import React from 'react';

import FAQAccordionList from 'components/ContentRecord/components/FAQAccordionList/FAQAccordionList';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import { IFAQ } from 'models/faq';

type RelatedFAQsBlockProps = {
  heading?: string;
  faqs?: IFAQ[];
  fluid?: boolean;
  className?: string;
  noPadding?: boolean;
};
const RelatedFAQsBlock: React.FC<RelatedFAQsBlockProps> = ({
  heading,
  faqs,
  fluid,
  ...props
}) => {
  return (
    <SectionWithContainer {...props} containerProps={{ fluid }}>
      <RowWithOffsetCol>
        {heading && (
          <h2
            className="mb-5 heading heading-danger"
            data-content="page_page_block_heading"
          >
            {heading}
          </h2>
        )}
        <FAQAccordionList faqs={faqs} />
      </RowWithOffsetCol>
    </SectionWithContainer>
  );
};

export default RelatedFAQsBlock;
