import React from 'react';

import Card from 'components/Card/Card';
import PageBlockBodyContent from 'components/PageBlocks/PageBlockBodyContent/PageBlockBodyContent';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { IArticleBlock } from 'models/articleBlock';
import { IPageBlock } from 'models/pageBlock';

import s from './SimpleCardBlock.module.scss';

interface IProps {
  pageBlock: IPageBlock | IArticleBlock;
}

const SimpleCardBlock: React.FC<IProps> = ({ pageBlock, ...props }) => {
  return (
    <SectionWithContainer
      key={pageBlock.id}
      {...props}
      containerProps={{ fluid: pageBlock.fluid_container_ind }}
    >
      <Card>
        {pageBlock.image?.url && (
          <img
            loading="lazy"
            src={pageBlock.image?.url}
            alt={pageBlock.image_alt}
            className={s.image}
          />
        )}
        <Card.Body className="p-4">
          <PageBlockBodyContent
            withOffset
            headingProps={{ className: 'heading heading-danger' }}
            bodyProps={{ className: 'text-center' }}
            pageBlock={pageBlock}
            hideHeading={!!pageBlock.image?.url}
          />
        </Card.Body>
      </Card>
    </SectionWithContainer>
  );
};

export default SimpleCardBlock;
