import React from 'react';
import { Button, Row } from 'react-bootstrap';

import cx from 'classnames';

import Card from 'components/Card/Card';
import CircleAvatarOverflow from 'components/CircleAvatarOverflow/CircleAvatarOverflow';
import HorizontalRoundedCornerContent from 'components/HorizontalRoundedCornerContent/HorizontalRoundedCornerContent';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { IColourSchema } from 'models/colourSchema';
import { ITemporaryDraw } from 'models/draw';

import s from './LotteryWidgetBlock.module.scss';

type LotteryWidgetBlockProps = {
  draw?: ITemporaryDraw;
  fluid?: boolean;
  colourSchema?: IColourSchema;
  className?: string;
  noPadding?: boolean;
};
const LotteryWidgetBlock: React.FC<LotteryWidgetBlockProps> = ({
  fluid,
  draw,
  ...props
}) => {
  return (
    <SectionWithContainer {...props} containerProps={{ fluid }}>
      <HorizontalRoundedCornerContent
        className={cx('mx-n3 mx-sm-0 border-radius-sm', s['main-card'])}
        rowProps={{ className: 'flex-column-reverse flex-lg-row' }}
      >
        <HorizontalRoundedCornerContent.Col className="bg-secondary">
          <h2 className="heading heading-danger">
            Buy a Surf Life Saving Lotteries ticket
          </h2>
          <p className="m-0">
            Another way to support the Surf Life Saving Foundation is by
            purchasing lottery tickets. You could be in with the chance to win a
            stunning new home, a car or gold!
          </p>
          <Button className="mt-4" href="https://www.surflottery.com.au/">
            Visit Surf Life Saving Lotteries
          </Button>
        </HorizontalRoundedCornerContent.Col>
        <HorizontalRoundedCornerContent.Col imgURL={draw?.media?.url} />
      </HorizontalRoundedCornerContent>
      <Card className={cx('bg-primary mx-n3 mx-sm-0 m-md-0', s['second-bar'])}>
        <Card.Body className="p-3 py-lg-2 px-lg-4">
          <Row className="m-0 flex-column flex-lg-row justify-content-between flex-nowrap flex-grow-0 align-items-center">
            <CircleAvatarOverflow
              size="md"
              className="mr-lg-3 flex-shrink-0 bg-white my-n3 ml-n3 m-0 d-none d-lg-block"
            >
              <img
                loading="lazy"
                src="/imgs/avatar2.png"
                alt="surf life saver"
              />
            </CircleAvatarOverflow>
            <h3 className="text-secondary flex-grow-0 flex-shrink-0 mr-lg-4 m-0">
              Your best tickets!
            </h3>
            <p className="text-white flex-grow-1 flex-shrink-1 mr-lg-4 m-0 text-center text-lg-left mb-2 mb-lg-0">
              Our <strong>$50 ticket</strong> gives you 70 chances to win and
              includes a bonus prize of $50,000 in Gold Bullion.
            </p>
            <Button
              as="a"
              variant="danger"
              href="https://www.surflottery.com.au/"
              target="_blank"
              className="flex-grow-0 flex-shrink-0"
            >
              Buy Now
            </Button>
          </Row>
        </Card.Body>
      </Card>
    </SectionWithContainer>
  );
};

export default LotteryWidgetBlock;
