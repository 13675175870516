import Link from 'next/link';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import VerticalTile from 'components/VerticalTile/VerticalTile';
import { IArticle } from 'models/article';
import { IArticleCategory } from 'models/articleTag';

interface ArticleList {
  articles?: IArticle[];
  onTagClick?: (tag: IArticleCategory) => void;
  className?: string;
  loading?: boolean;
  colProps?: any;
}
const ArticlesList: React.FC<ArticleList> = ({
  onTagClick,
  articles,
  className,
  loading,
  colProps,
}) => {
  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (!articles?.length) {
    return <h1>There is no Articles</h1>;
  }

  return (
    <Row className={className}>
      {articles.map((article: IArticle) => (
        <Col
          key={article.id}
          md={6}
          lg={4}
          className="my-3 d-flex"
          {...colProps}
        >
          <VerticalTile
            className="no-shadow bg-light-grey w-100"
            imageURL={article?.thumbnail_image?.url as string}
            slug={article.slug}
          >
            <div className="d-flex flex-column align-items-center">
              <h4 className="heading heading-small heading-danger mb-3 w-100">
                <Link
                  as={`/article/${article.slug}`}
                  href={{
                    pathname: '/article/[slug]',
                    query: { slug: article.slug },
                  }}
                  passHref
                >
                  <a className="text-black" data-content="article_list_title">
                    {article.title}
                  </a>
                </Link>
              </h4>
              <div className="align-self-center">
                {article?.categories?.length ? (
                  article.categories.map(
                    (tag: IArticleCategory, index: number) => (
                      <Button
                        key={tag.id}
                        onClick={() => onTagClick && onTagClick(tag)}
                        variant="link"
                        size="sm"
                        className="p-0 text-black"
                      >
                        {tag.name}
                        {index + 1 !== article?.categories?.length && ','}
                      </Button>
                    ),
                  )
                ) : (
                  <span style={{ visibility: 'hidden' }}>&nbsp;</span>
                )}
              </div>
              <Link
                as={`/article/${article.slug}`}
                href={{
                  pathname: '/article/[slug]',
                  query: { slug: article.slug },
                }}
                passHref
              >
                <Button
                  as="a"
                  className="text-primary font-weight-bold p-0"
                  variant="link"
                >
                  Read more
                </Button>
              </Link>
            </div>
          </VerticalTile>
        </Col>
      ))}
    </Row>
  );
};

export default ArticlesList;
