import Link from 'next/link';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';

import DonationOptionGrid from 'components/DonationOptionGrid/DonationOptionGrid';
import SectionWithContainer, {
  ISectionWithContainerProps,
} from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import { IDonationCampaign } from 'models/donationCampaign';
import { IPageBlock } from 'models/pageBlock';

interface IGiftGivingCampaignBlockProps extends ISectionWithContainerProps {
  pageBlock: IPageBlock;
  fluid?: boolean;
  donationCampaign?: IDonationCampaign;
  className?: string;
  noPadding?: boolean;
}
const GiftGivingCampaignBlock: React.FC<IGiftGivingCampaignBlockProps> = ({
  fluid,
  pageBlock,
  containerProps = {},
  ...props
}) => {
  const randomDonationOptions = useMemo(() => {
    return pageBlock.donation_campaign?.donation_options
      ?.sort(() => (Math.random() < 0.5 ? 1 : -1))
      ?.slice(0, 4);
  }, [pageBlock.donation_campaign?.donation_options]);
  return (
    <SectionWithContainer
      {...props}
      containerProps={{ ...containerProps, fluid }}
    >
      <RowWithOffsetCol className="mb-4">
        <h2 className="heading heading-danger">{pageBlock.heading}</h2>
      </RowWithOffsetCol>
      {!!randomDonationOptions?.length && pageBlock.donation_campaign && (
        <DonationOptionGrid
          donationCampaignOptions={randomDonationOptions}
          donationCampaignSlug={pageBlock.donation_campaign.slug}
        />
      )}
      {pageBlock.donation_campaign && (
        <div className="d-flex justify-content-center mt-4">
          <Link
            href={`/campaign/${pageBlock.donation_campaign?.slug}`}
            passHref
          >
            <Button as="a" variant="danger">
              See more gifts
            </Button>
          </Link>
        </div>
      )}
    </SectionWithContainer>
  );
};

export default GiftGivingCampaignBlock;
