import React from 'react';

import { instanceOfPageBlockContent } from 'utils/checkInterface';

import PageBlockContentBody from 'components/PageBlocks/PageBlockContents/PageBlockContentBody/PageBlockContentBody';
import VerticalTile from 'components/VerticalTile/VerticalTile';
import { IArticleBlockContent } from 'models/articleBlockContent';
import { IPageBlockContent } from 'models/pageBlockContent';

type VerticalTileContentProps = {
  pageBlockContent: IPageBlockContent | IArticleBlockContent;
};
const VerticalTileContent: React.FC<VerticalTileContentProps> = ({
  pageBlockContent,
}) => {
  const isInstanceOfPageBlockContent =
    instanceOfPageBlockContent(pageBlockContent);

  return (
    <VerticalTile
      className="bg-light-grey no-shadow flex-grow-1"
      imageURL={pageBlockContent.media?.url}
    >
      <PageBlockContentBody
        hubspotFormID={
          isInstanceOfPageBlockContent
            ? pageBlockContent.hubspot_form?.form_id
            : undefined
        }
        heading={pageBlockContent.heading}
        headingSize={pageBlockContent.heading_size?.name}
        blockBody={
          isInstanceOfPageBlockContent
            ? pageBlockContent.page_block_body
            : pageBlockContent.block_body
        }
        bodyProps={{ className: 'flex-grow-1' }}
        headingProps={{ className: `heading heading-danger heading-small` }}
        link={pageBlockContent.link}
        linkLabel={pageBlockContent.link_label}
        downloadFile={pageBlockContent.download_file}
        btnProps={
          pageBlockContent.render_link_as_button
            ? {
                variant: 'danger',
                className: 'text-white font-weight-normal px-3 py-1',
                style: { fontSize: '0.9rem', fontWeight: 600 },
              }
            : {
                variant: 'link',
                className: 'text-primary font-weight-normal mt-0 p-0',
              }
        }
      />
    </VerticalTile>
  );
};

export default VerticalTileContent;
