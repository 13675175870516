import React from 'react';

import PageBlockContentBody from 'components/PageBlocks/PageBlockContents/PageBlockContentBody/PageBlockContentBody';
import VerticalTileContent from 'components/PageBlocks/PageBlockContents/VerticalTileContent/VerticalTileContent';
import { IArticleBlockContent } from 'models/articleBlockContent';
import { IPageBlockContent } from 'models/pageBlockContent';
import PageBlockContentTypes from 'models/pageBlockContentLayouts';

import { instanceOfPageBlockContent } from './checkInterface';

const renderBlockContentByType = (
  blockContent: IPageBlockContent | IArticleBlockContent,
): JSX.Element => {
  const blockType = instanceOfPageBlockContent(blockContent)
    ? blockContent.page_block_content_layout?.name
    : blockContent.page_block_content_layout_id;

  switch (blockType) {
    case PageBlockContentTypes.VERTICAL_TILE:
      return <VerticalTileContent pageBlockContent={blockContent} />;
    case PageBlockContentTypes.SIMPLE_COLUMN:
      if (!instanceOfPageBlockContent(blockContent)) {
        return <h1>There is no Block Content: {blockType}</h1>;
      }
      return (
        <PageBlockContentBody
          imageOnTop={blockContent.media?.url}
          imageOnTopAlt={blockContent.media_alt}
          hubspotFormID={blockContent.hubspot_form?.form_id}
          heading={blockContent.heading}
          headingSize={blockContent.heading_size?.name}
          blockBody={blockContent.page_block_body}
          bodyProps={{ className: 'flex-grow-1' }}
          headingProps={{ className: `heading heading-danger heading-small` }}
          link={blockContent.link}
          linkLabel={blockContent.link_label}
          downloadFile={blockContent.download_file}
          btnProps={{
            variant: 'link',
            className: 'text-primary font-weight-bold mt-0 p-0',
          }}
        />
      );
    default:
      return <h1>There is no Block Content: {blockType}</h1>;
  }
};
export default renderBlockContentByType;
