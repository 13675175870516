import React from 'react';
import { Col, Row } from 'react-bootstrap';

import renderBlockContentByType from 'utils/renderBlockContentByType';

import { IArticleBlockContent } from 'models/articleBlockContent';
import { IPageBlockContent } from 'models/pageBlockContent';

const renderBlockContents = (
  blockContents: IPageBlockContent[] | IArticleBlockContent[],
): JSX.Element => {
  return (
    <Row className="my-n4 my-lg-n5 justify-content-center">
      {blockContents.map(
        (blockContent: IPageBlockContent | IArticleBlockContent) => {
          return (
            <Col
              key={blockContent.id}
              lg={blockContent.column_width}
              className="pt-3 pt-lg-4 pb-3 pb-sm-4 pb-lg-5 d-flex flex-column"
            >
              {renderBlockContentByType(blockContent)}
            </Col>
          );
        },
      )}
    </Row>
  );
};

export default renderBlockContents;
