import React, { FunctionComponent, useCallback } from 'react';
import { Button, Form, FormControlProps } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import cx from 'classnames';
import { Validation } from 'utils/validationForm';

import FormRadioButtonGroup from 'components/FormRadioButtonGroup/FormRadioButtonGroup';

const SHOW_NAME_OPTIONS = [
  {
    value: true,
    label: 'Show my name beside my message.',
  },
  {
    value: false,
    label: 'Do not show my name beside my message.',
  },
];

type MessageOfSupportFormProps = {
  loading?: boolean;
  dark?: boolean;
  onSubmit(data: any): void;
  formControlProps?: FormControlProps;
};
const MessageOfSupportForm: FunctionComponent<MessageOfSupportFormProps> = ({
  loading,
  onSubmit,
  dark = false,
  formControlProps = {},
}) => {
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      email: '',
      name: '',
      message: '',
      show_name: SHOW_NAME_OPTIONS[0],
    },
  });
  const beforeSubmit = useCallback(
    values => {
      onSubmit({ ...values, show_name: values.show_name.value });
    },
    [onSubmit],
  );
  return (
    <Form onSubmit={handleSubmit(beforeSubmit)}>
      <Form.Group>
        <Form.Control
          name="name"
          className={cx(
            {
              'is-invalid': errors?.name,
            },

            formControlProps.className,
          )}
          placeholder="Enter your name"
          ref={register({ required: Validation.required })}
          {...formControlProps}
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="email"
          name="email"
          className={cx(
            {
              'is-invalid': errors?.email,
            },
            formControlProps.className,
          )}
          placeholder="Enter your email"
          ref={register({
            pattern: Validation.email,
            required: Validation.required,
          })}
          {...formControlProps}
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          rows={6}
          name="message"
          className={cx(
            {
              'is-invalid': errors?.message,
            },
            formControlProps.className,
          )}
          placeholder="Add your message"
          ref={register({ required: Validation.required })}
          maxLength={200}
          as="textarea"
          {...formControlProps}
        />
      </Form.Group>
      <Form.Group>
        <Controller
          control={control}
          as={FormRadioButtonGroup}
          id="show_name"
          name="show_name"
          className={cx({
            'is-invalid': errors?.show_name,
          })}
          rules={{ required: true }}
          labelProps={{
            className: cx('ml-2 d-block mb-2', { 'text-white': dark }),
          }}
          options={SHOW_NAME_OPTIONS}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className="d-flex">
          <Form.Check
            name="opt_in_marketing"
            value={1}
            ref={register}
            className="mr-2"
          />
          <p className="text-normal">
            Receive updates on how your donation has helped Surf Life Saving,
            plus hear about real life rescue stories and exciting new ventures.
          </p>
        </Form.Label>
      </Form.Group>
      <Form.Group>
        <Button
          disabled={loading}
          type="submit"
          variant="secondary"
          className="text-danger"
        >
          Leave Message
        </Button>
      </Form.Group>
    </Form>
  );
};

export default MessageOfSupportForm;
