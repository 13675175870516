import Link from 'next/link';
import React from 'react';
import { Button } from 'react-bootstrap';

import HorizontalRoundedCornerContent from 'components/HorizontalRoundedCornerContent/HorizontalRoundedCornerContent';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';

interface IProps {
  colourSchema?: string;
  fluid?: boolean;
}

const GOSFeaturedBlock: React.FC<IProps> = ({
  colourSchema = 'primary',
  fluid,
  ...props
}) => {
  const { btnColor, bgColor, headingColor, textColor } =
    colourSchema === 'secondary'
      ? {
          bgColor: 'gos-scheme',
          headingColor: 'danger',
          textColor: '',
          btnColor: 'primary',
        }
      : {
          bgColor: 'gos-scheme',
          headingColor: 'secondary',
          textColor: 'white',
          btnColor: 'danger',
        };

  return (
    <SectionWithContainer {...props} containerProps={{ fluid }}>
      <HorizontalRoundedCornerContent>
        <HorizontalRoundedCornerContent.Col imgURL="/imgs/gosBackground.jpg" />
        <HorizontalRoundedCornerContent.Col
          className={`bg-${bgColor} text-${textColor} py-4`}
        >
          <h2 className={`heading heading-${headingColor} `}>
            Guardians of the Surf
          </h2>
          <p>
            Your monthly donation will help train new surf lifesavers, improve
            the skills of our current volunteers and help us replace equipment
            that is constantly exposed to the harsh Australian conditions.
          </p>
          <Link href="/become-a-guardian-of-the-surf" passHref>
            <Button as="a" variant={btnColor} className="mt-4">
              Become a Guardian of the Surf
            </Button>
          </Link>
        </HorizontalRoundedCornerContent.Col>
      </HorizontalRoundedCornerContent>
    </SectionWithContainer>
  );
};

export default GOSFeaturedBlock;
