import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import Circle from 'components/Circle/Circle';
import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { IBoardMember } from 'models/boardMember';

interface BoardMembersBlockProps {
  boardMembers?: IBoardMember[];
  fluid?: boolean;
  className?: string;
  noPadding?: boolean;
  heading?: string;
}
const BoardMembersBlock: React.FC<BoardMembersBlockProps> = ({
  fluid,
  boardMembers,
  heading,
  ...props
}) => {
  const [selectedBoarMemberIndex, setSelectedBoarMemberIndex] = useState(0);

  if (!boardMembers?.length) {
    return <h1>There is no board Members</h1>;
  }

  const selectedBoardMember = boardMembers[selectedBoarMemberIndex];
  const SLIDER_SETTINGS = {
    dots: false,
    arrows: false,
    autoplay: false,
    infinite: false,
    variableWidth: true,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 845,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderMemberListItems = (): JSX.Element[] => {
    return boardMembers.map((boardMember: IBoardMember, index: number) => (
      <div key={boardMember.id} className="py-2 px-2 px-lg-0">
        <Button
          variant="light-grey"
          size="sm"
          onClick={() => setSelectedBoarMemberIndex(index)}
          className="text-left text-black d-block w-100 p-3"
        >
          <div>
            <strong>{boardMember.name}</strong>
          </div>
          <span>{boardMember.position}</span>
        </Button>
      </div>
    ));
  };

  return (
    <SectionWithContainer {...props} containerProps={{ fluid }}>
      <Row>
        <Col lg={3} className="d-flex flex-column mb-3">
          <h4>{heading}</h4>
          <div className="d-none d-lg-flex flex-column">
            {renderMemberListItems()}
          </div>
          <DynamicSlider
            {...SLIDER_SETTINGS}
            className="flex-grow-1 d-block d-lg-none mx-n3"
          >
            {renderMemberListItems()}
          </DynamicSlider>
        </Col>
        <Col>
          <Row className="flex-column flex-sm-row align-items-center align-items-sm-stretch">
            <Col className="mb-3">
              <h3 className="heading heading-danger">
                <div data-content="board_member_name">
                  {selectedBoardMember.name} -
                </div>
                <div data-content="board_member_position">
                  {selectedBoardMember.position}
                </div>
              </h3>
            </Col>
            <Col className="flex-grow-0 min-w-auto mb-3">
              <Circle
                className="flex-grow-0 mb-sm-n8 mb-0 mx-auto"
                key="circle"
                size="xl"
              >
                <img
                  loading="lazy"
                  src={selectedBoardMember.headshot_image.url as string}
                  alt={selectedBoardMember.name}
                />
              </Circle>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={7}>
              <div
                data-content="board_member_biography"
                dangerouslySetInnerHTML={{
                  __html: selectedBoardMember.biography,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </SectionWithContainer>
  );
};

export default BoardMembersBlock;
