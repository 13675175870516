import React from 'react';

import cx from 'classnames';

import PageBlockBodyContent from 'components/PageBlocks/PageBlockBodyContent/PageBlockBodyContent';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { IPageBlock } from 'models/pageBlock';

import s from './QuoteOnMediaBlock.module.scss';

interface IProps {
  pageBlock: IPageBlock;
}
const QuoteOnMediaBlock: React.FC<IProps> = ({ pageBlock, ...props }) => {
  return (
    <SectionWithContainer
      {...props}
      className={s['quote-on-media-content']}
      style={{
        backgroundImage: `url(${pageBlock.image?.url})`,
      }}
    >
      <div className="z-100 d-flex position-relative flex-column align-items-center justify-content-center py-6 px-4">
        <PageBlockBodyContent
          headingProps={{
            className: 'heading heading-danger text-white mb-n5 w-100',
          }}
          bodyProps={{
            className: cx('text-white col-lg-8', s['quote-on-media-body']),
          }}
          hubspotTheme="dark"
          relatedLinksButtonProps={{ variant: 'danger' }}
          pageBlock={pageBlock}
        />
      </div>
    </SectionWithContainer>
  );
};

export default QuoteOnMediaBlock;
