import React from 'react';
import { Col, Row } from 'react-bootstrap';

import cx from 'classnames';

import CircleAvatarOverflow from 'components/CircleAvatarOverflow/CircleAvatarOverflow';
import LinkButtonRow from 'components/LinkButtonRow/LinkButtonRow';
import SectionWithContainer, {
  ISectionWithContainerProps,
} from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { IArticleBlock } from 'models/articleBlock';
import { IDonationCampaign } from 'models/donationCampaign';
import { IPageBlock } from 'models/pageBlock';
import { IThankYouMessage } from 'models/thankYouMessage';

interface ICrossReferenceBannerBlockProps extends ISectionWithContainerProps {
  pageBlock: IPageBlock | IArticleBlock;
  fluid?: boolean;
  supportiveMessages?: IThankYouMessage[];
  donationCampaign?: IDonationCampaign;
  className?: string;
  noPadding?: boolean;
}
const CrossReferenceBannerBlock: React.FC<ICrossReferenceBannerBlockProps> = ({
  fluid,
  className,
  pageBlock,
  containerProps = {},
  ...props
}) => {
  return (
    <SectionWithContainer
      containerProps={{ fluid, ...containerProps }}
      {...props}
      className={cx('bg-light-grey', className)}
    >
      <Row className="justify-content-center justify-content-lg-start flex-wrap flex-lg-nowrap mt-4">
        {pageBlock.image?.url && (
          <Col xs="auto" className="mb-4 mb-md-0">
            <CircleAvatarOverflow size="lg" offset={60} className="bg-white">
              <img src={pageBlock.image.url} alt={pageBlock.heading} />
            </CircleAvatarOverflow>
          </Col>
        )}
        <Col lg="auto" className="flex-shrink-1">
          {pageBlock.heading && (
            <h1 className="text-center text-md-left">{pageBlock.heading}</h1>
          )}
          {pageBlock.body && (
            <div
              dangerouslySetInnerHTML={{
                __html: pageBlock.body,
              }}
            />
          )}
          <LinkButtonRow links={pageBlock.related_links || []} />
        </Col>
      </Row>
    </SectionWithContainer>
  );
};

export default CrossReferenceBannerBlock;
