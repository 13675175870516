import { IArticle } from 'models/article';

import { EntityService } from './entityService';
import HttpRequestor, { BaseResponse } from './httpRequestor';

export class ArticleClientService extends EntityService<IArticle> {
  constructor(httpRequestor: HttpRequestor) {
    super(httpRequestor, 'article');
  }

  getOneByPageName(
    page: string,
    options?: any,
  ): Promise<BaseResponse<IArticle>> {
    return this.httpRequestor.get(`/${this.entity}/${page}`, options);
  }

  async getAllArticles(options?: any): Promise<IArticle[]> {
    const { data } = await this.getAll(options);
    return data;
  }
  // Write down the specifics when needed
}
