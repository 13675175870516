import React from 'react';

import cx from 'classnames';

import Card from 'components/Card/Card';

import s from './SpeechBubble.module.scss';

type SpeechBubbleReactProps = {
  Body: typeof Card.Body;
  Header: typeof Card.Header;
};

type SpeechBubbleProps = {
  cardProps?: any;
  className?: string;
  style?: any;
};

const SpeechBubble: React.FC<SpeechBubbleProps> & SpeechBubbleReactProps = ({
  children,
  className,
  cardProps,
  ...props
}) => {
  return (
    <div className={cx(s['speech-bubble'], className)} {...props}>
      <Card {...(cardProps || {})}>{children}</Card>
    </div>
  );
};

export default SpeechBubble;
SpeechBubble.Body = Card.Body;
SpeechBubble.Header = Card.Header;
