import React from 'react';

import getMessageActionText from 'utils/getMessageActionText';

import SpeechBubble from 'components/SpeechBubble/SpeechBubble';
import { IThankYouMessage } from 'models/thankYouMessage';

interface IMessageContentProps {
  supportiveMessage: IThankYouMessage;
}

const MessageContent = ({ supportiveMessage }: IMessageContentProps) => {
  const { sales_order_item, customer, message, show_name } = supportiveMessage;

  return (
    <SpeechBubble.Body className="px-4">
      <div className="d-flex align-items-center">
        <img
          loading="lazy"
          src={
            sales_order_item?.donation_option?.image.url ||
            '/imgs/red-heart.png'
          }
          alt="message"
          width="42"
          height="46"
          className="mr-3"
        />
        <div className="text-primary">
          <h4>
            {(show_name && customer?.first_name) || 'A Surf supporter'}
            {customer?.suburb ? ` from ${customer?.suburb}` : ''}
          </h4>
          <p>{getMessageActionText(sales_order_item)}</p>
        </div>
      </div>

      <p className="font-italic text-black">"{message}"</p>
    </SpeechBubble.Body>
  );
};

export default MessageContent;
