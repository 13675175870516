import Link from 'next/link';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import cx from 'classnames';

import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import HorizontalRoundedCornerContent from 'components/HorizontalRoundedCornerContent/HorizontalRoundedCornerContent';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import SLSFProgressBar, {
  SLSFProgressBarSizes,
} from 'components/SLSFProgressBar/SLSFProgressBar';
import VerticalTile from 'components/VerticalTile/VerticalTile';
import { IDonationCampaign } from 'models/donationCampaign';

const SLIDER_SETTINGS = {
  dots: false,
  speed: 500,
  arrows: false,
  autoplay: false,
  className: 'slider variable-width',
  centerMode: true,
  infinite: false,
  // centerPadding: '60px',
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

type CurrentAppealsBlockProps = {
  fluid?: boolean;
  donationCampaigns: IDonationCampaign[];
  noPadding?: boolean;
  className?: string;
};

const DonationCampaignVerticalTile: React.FC<{
  donationCampaign: IDonationCampaign;
}> = ({ donationCampaign, children }) => {
  return (
    <VerticalTile
      textOnTopOfImage={
        donationCampaign.heading_image ? (
          <img
            height="auto"
            width="100%"
            loading="lazy"
            src={donationCampaign.heading_image.url}
            alt={donationCampaign.heading_image_alt}
          />
        ) : (
          <h3 className="text-center text-white">{donationCampaign.heading}</h3>
        )
      }
      className="bg-primary text-white no-shadow flex-grow-1"
      imageURL={donationCampaign.banner_image?.url as string}
    >
      {children}
    </VerticalTile>
  );
};

const CurrentAppealsBlock: React.FC<CurrentAppealsBlockProps> = ({
  fluid,
  donationCampaigns,
  className,
  noPadding,
  ...props
}) => {
  const slideVariableWidth = donationCampaigns.length >= 3;
  const renderBody = (donationCampaign: IDonationCampaign): JSX.Element => {
    return (
      <>
        <h3 data-content="page_donation_campaign_heading">
          {donationCampaign.heading}
        </h3>
        <SLSFProgressBar
          size={SLSFProgressBarSizes.sm}
          className="w-100 my-3"
          amountAchieved={donationCampaign.raised_amount as number}
          amountToAchieve={donationCampaign.goal_amount as number}
        />
        <Link
          as={`/campaign/${donationCampaign.slug}`}
          href={{
            pathname: '/campaign/[slug]',
            query: { slug: donationCampaign.slug },
          }}
        >
          <Button as="a" variant="danger">
            Support this appeal
          </Button>
        </Link>
      </>
    );
  };

  function renderCampaigns() {
    if (!donationCampaigns.length) {
      return <h1> There is no campaigns currently </h1>;
    }

    if (donationCampaigns.length === 1) {
      return (
        <Container>
          <HorizontalRoundedCornerContent>
            <HorizontalRoundedCornerContent.Col
              backdrop
              imgURL={donationCampaigns[0].banner_image?.url as string}
            >
              <div className="d-flex flex-grow-1 flex-column justify-content-center px-5">
                {donationCampaigns[0]?.heading_image ? (
                  <img
                    loading="lazy"
                    src={donationCampaigns[0]?.heading_image?.url}
                    alt={donationCampaigns[0].heading_image_alt}
                  />
                ) : (
                  <h3 className="text-white">
                    {donationCampaigns[0]?.heading}
                  </h3>
                )}
              </div>
            </HorizontalRoundedCornerContent.Col>
            <HorizontalRoundedCornerContent.Col className="bg-primary text-white">
              {renderBody(donationCampaigns[0])}
            </HorizontalRoundedCornerContent.Col>
          </HorizontalRoundedCornerContent>
        </Container>
      );
    }

    if (donationCampaigns.length === 2) {
      return (
        <Container>
          <Row>
            {donationCampaigns.map((donationCampaign: IDonationCampaign) => (
              <Col lg={6} key={donationCampaign.id} className="mb-4 mb-lg-0">
                <DonationCampaignVerticalTile
                  donationCampaign={donationCampaign}
                >
                  {renderBody(donationCampaign)}
                </DonationCampaignVerticalTile>
              </Col>
            ))}
          </Row>
        </Container>
      );
    }

    return (
      <DynamicSlider
        {...SLIDER_SETTINGS}
        variableWidth={slideVariableWidth}
        initialSlide={1}
      >
        {donationCampaigns.map((donationCampaign: IDonationCampaign) => (
          <div
            key={donationCampaign.id}
            className="px-2 d-flex flex-column"
            style={{
              width: '420px',
            }}
          >
            <DonationCampaignVerticalTile donationCampaign={donationCampaign}>
              {renderBody(donationCampaign)}
            </DonationCampaignVerticalTile>
          </div>
        ))}
      </DynamicSlider>
    );
  }

  return (
    <section className={cx({ 'py-4': !noPadding }, className)} {...props}>
      <Container fluid={fluid}>
        <RowWithOffsetCol className="mb-5">
          <Row className="align-items-center">
            <Col xl={7}>
              <h2 className="heading heading-full heading-danger d-inline-block">
                Our Appeals
              </h2>
              <p>
                It's through our appeals that all Australians can really help
                make a difference. By donating whatever you can, you are helping
                keep volunteer surf lifesavers on our beaches and helping us
                strive for zero deaths on our beaches. All gifts over $2 are tax
                deductible.
              </p>
            </Col>
            <Col xl={5} className="px-7">
              <img
                height="auto"
                width="100%"
                loading="lazy"
                className="w-100 d-block py-5"
                src="/imgs/mission-zero.svg"
                alt="Mission Zero"
              />
            </Col>
          </Row>
        </RowWithOffsetCol>
      </Container>
      {renderCampaigns()}
    </section>
  );
};

export default CurrentAppealsBlock;
