import React from 'react';

import AnimatedTreeBlock from 'components/PageBlocks/AnimatedTreeBlock/AnimatedTreeBlock';
import ArticlesListAndSearchBlock from 'components/PageBlocks/ArticlesListAndSearchBlock/ArticlesListAndSearchBlock';
import BoardMembersBlock from 'components/PageBlocks/BoardMembersBlock/BoardMembersBlock';
import CircleImageWithBodyBlock from 'components/PageBlocks/CircleImageWithBodyBlock/CircleImageWithBodyBlock';
import CrossReferenceBannerBlock from 'components/PageBlocks/CrossReferenceBannerBlock/CrossReferenceBannerBlock';
import CurrentAppealsBlock from 'components/PageBlocks/CurrentAppealsBlock/CurrentAppealsBlock';
import CurrentAppealsCompactBlock from 'components/PageBlocks/CurrentAppealsCompactBlock/CurrentAppealsCompactBlock';
import CustomBlock from 'components/PageBlocks/CustomBlock/CustomBlock';
import DonationWidgetBlock from 'components/PageBlocks/DonationWidgetBlock/DonationWidgetBlock';
import GiftGivingCampaignBlock from 'components/PageBlocks/GiftGivingCampaignBlock/GiftGivingCampaignBlock';
import GOSFeaturedBlock from 'components/PageBlocks/GOSFeaturedBlock/GOSFeaturedBlock';
import GOSStatusBlock from 'components/PageBlocks/GOSStatusBlock/GOSStatusBlock';
import LeaveFestiveSupportMessageBlock from 'components/PageBlocks/LeaveFestiveSupportMessageBlock/LeaveFestiveSupportMessageBlock';
import LotteryWidgetBlock from 'components/PageBlocks/LotteryWidgetBlock/LotteryWidgetBlock';
import MessageCarouselBlock from 'components/PageBlocks/MessageCarouselBlock/MessageCarouselBlock';
import NewsletterSignUpBlock from 'components/PageBlocks/NewsletterSignUpBlock/NewsletterSignUpBlock';
import QuoteOnMediaBlock from 'components/PageBlocks/QuoteOnMediaBlock/QuoteOnMediaBlock';
import RelatedFAQsBlock from 'components/PageBlocks/RelatedFAQsBlock/RelatedFAQsBlock';
import RelatedLinksBlock from 'components/PageBlocks/RelatedLinksBlock/RelatedLinksBlock';
import RoundedImageWithBodyBlock from 'components/PageBlocks/RoundedImageWithBodyBlock/RoundedImageWithBodyBlock';
import SimpleCardBlock from 'components/PageBlocks/SimpleCardBlock/SimpleCardBlock';
import SquaredImageWithBodyBlock from 'components/PageBlocks/SquaredImageWithBodyBlock/SquaredImageWithBodyBlock';
import StatisticCardsBlock from 'components/PageBlocks/StatisticCardsBlock/StatisticCardsBlock';
import SupportiveMessagesBlock from 'components/PageBlocks/SupportiveMessagesBlock/SupportiveMessagesBlock';
import VerticalTilesBlock from 'components/PageBlocks/VerticalTilesBlock/VerticalTilesBlock';
import YellowCircleBackgroundBlock from 'components/PageBlocks/YellowCircleBackgroundBlock/YellowCircleBackgroundBlock';
import { IArticle } from 'models/article';
import { IArticleBlock } from 'models/articleBlock';
import { IBoardMember } from 'models/boardMember';
import { IDonationCampaign } from 'models/donationCampaign';
import { IGOSStatus } from 'models/gosStatus';
import { IPageBlock } from 'models/pageBlock';
import PageBlockTypes from 'models/pageBlockTypes';
import { IStatistic } from 'models/statistic';
import { IThankYouMessage } from 'models/thankYouMessage';

import { instanceOfPageBlock } from './checkInterface';

const renderBlocks = (
  pageBlocks: IPageBlock[] | IArticleBlock[],
  donationCampaigns?: IDonationCampaign[],
  supportiveMessages?: IThankYouMessage[],
  boardMembers?: IBoardMember[],
  articles?: IArticle[],
  gosStatus?: IGOSStatus[],
  statistics?: IStatistic[],
  xmasMessages?: IThankYouMessage[],
  hasHeader = false,
  showCustomTreeHeader = false,
): JSX.Element[] => {
  const defaultBlock = (pageBlock: IPageBlock | IArticleBlock) => {
    return (
      <h1 key={pageBlock.id}>
        Block type {pageBlock.block_type?.name} not found
      </h1>
    );
  };
  return pageBlocks.map((pageBlock, index: number) => {
    const isInstanceOfPageBlock = instanceOfPageBlock(pageBlock);
    let commonProps = {};
    if (showCustomTreeHeader && index === 0) {
      commonProps = { className: 'd-none' };
    }
    if (!showCustomTreeHeader && hasHeader && index === 0) {
      commonProps = { className: 'mt-0 mt-sm-n8 pb-4 z-100', noPadding: true };
    }

    switch (pageBlock.block_type?.name || pageBlock.block_type) {
      case PageBlockTypes.DONATION_WIDGET: {
        if (!isInstanceOfPageBlock) {
          return defaultBlock(pageBlock);
        }

        return (
          <DonationWidgetBlock
            colourSchema={pageBlock.colour_schema}
            campaignBackgroundImageURL={
              pageBlock.image?.url ||
              pageBlock.donation_campaign?.mobile_banner_image?.url
            }
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            donationCampaign={pageBlock.donation_campaign}
            {...commonProps}
          />
        );
      }
      case PageBlockTypes.CURRENT_APPEALS:
        return (
          <CurrentAppealsBlock
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            donationCampaigns={donationCampaigns as IDonationCampaign[]}
            {...commonProps}
          />
        );
      case PageBlockTypes.CURRENT_APPEALS_COMPACT:
        return (
          <CurrentAppealsCompactBlock
            key={pageBlock.id}
            donationCampaigns={donationCampaigns as IDonationCampaign[]}
            {...commonProps}
          />
        );
      case PageBlockTypes.SUPPORTIVE_MESSAGES:
        if (!isInstanceOfPageBlock) {
          return defaultBlock(pageBlock);
        }
        return (
          <SupportiveMessagesBlock
            key={pageBlock.id}
            donationCampaign={pageBlock.donation_campaign as IDonationCampaign}
            fluid={pageBlock.fluid_container_ind}
            supportiveMessages={supportiveMessages as IThankYouMessage[]}
            {...commonProps}
          />
        );
      case PageBlockTypes.RELATED_FAQS:
        if (!isInstanceOfPageBlock) {
          return defaultBlock(pageBlock);
        }
        return (
          <RelatedFAQsBlock
            heading={pageBlock.heading}
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            faqs={pageBlock.related_faqs}
            {...commonProps}
          />
        );
      case PageBlockTypes.LOTTERY_WIDGET:
        if (!isInstanceOfPageBlock) {
          return defaultBlock(pageBlock);
        }
        return (
          <LotteryWidgetBlock
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            draw={pageBlock.temporary_draw}
            {...commonProps}
          />
        );
      case PageBlockTypes.REGULAR_DONATION:
        if (!isInstanceOfPageBlock) {
          return defaultBlock(pageBlock);
        }
        return (
          <DonationWidgetBlock
            isRegularDonation
            campaignBackgroundImageURL={
              pageBlock.image?.url ||
              pageBlock.donation_campaign?.mobile_banner_image?.url
            }
            colourSchema={pageBlock.colour_schema}
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            donationCampaign={pageBlock.donation_campaign}
            {...commonProps}
          />
        );
      case PageBlockTypes.VERTICAL_TILES:
        return (
          <VerticalTilesBlock
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            blockContents={
              instanceOfPageBlock(pageBlock)
                ? pageBlock.page_block_contents
                : pageBlock.article_block_contents
            }
            {...commonProps}
          />
        );
      case PageBlockTypes.INVESTOR_BOARD_MEMBERS:
      case PageBlockTypes.BOARD_MEMBERS:
        return (
          <BoardMembersBlock
            heading={pageBlock.heading}
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            boardMembers={boardMembers}
            {...commonProps}
          />
        );
      case PageBlockTypes.ARTICLES_LIST_AND_SEARCH:
        return (
          <ArticlesListAndSearchBlock
            articlesPerPage={9}
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            initialArticles={articles}
            className="z-10 mt-sm-n5"
            {...commonProps}
          />
        );
      case PageBlockTypes.GOS_STATUS:
        return (
          <GOSStatusBlock
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            gosStatus={gosStatus}
            {...commonProps}
          />
        );
      case PageBlockTypes.NEWSLETTER_SIGN_UP:
        return (
          <NewsletterSignUpBlock
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            {...commonProps}
          />
        );
      case PageBlockTypes.RELATED_LINKS:
      case PageBlockTypes.RELATED_BUTTONS:
        return (
          <RelatedLinksBlock
            key={pageBlock.id}
            pageBlock={pageBlock}
            variant={
              PageBlockTypes.RELATED_LINKS === pageBlock.block_type?.name
                ? 'links'
                : 'primary'
            }
            {...commonProps}
          />
        );
      case PageBlockTypes.STATISTIC_CARDS:
        return (
          <StatisticCardsBlock
            statistics={statistics}
            title={pageBlock.heading}
            body={pageBlock.body}
            key={pageBlock.id}
            fluid={pageBlock.fluid_container_ind}
            {...commonProps}
          />
        );
      case PageBlockTypes.GOS_FEATURED_BLOCK:
        return (
          <GOSFeaturedBlock
            key={pageBlock.id}
            colourSchema={pageBlock.colour_schema?.name}
            {...commonProps}
          />
        );
      case PageBlockTypes.CUSTOM:
        return (
          <CustomBlock
            key={pageBlock.id}
            pageBlock={pageBlock}
            {...commonProps}
          />
        );
      case PageBlockTypes.YELLOW_CIRCLE_BACKGROUND:
        if (!isInstanceOfPageBlock) {
          return defaultBlock(pageBlock);
        }
        return (
          <YellowCircleBackgroundBlock
            key={pageBlock.id}
            {...commonProps}
            pageBlock={pageBlock}
          />
        );
      case PageBlockTypes.SQUARED_LEFT_IMAGE_RIGHT_BODY:
        return (
          <SquaredImageWithBodyBlock
            key={pageBlock.id}
            {...commonProps}
            flipped
            pageBlock={pageBlock}
          />
        );
      case PageBlockTypes.SQUARED_RIGHT_IMAGE_LEFT_BODY:
        return (
          <SquaredImageWithBodyBlock
            key={pageBlock.id}
            {...commonProps}
            pageBlock={pageBlock}
          />
        );
      case PageBlockTypes.CIRCLE_LEFT_IMAGE:
        return (
          <CircleImageWithBodyBlock
            key={pageBlock.id}
            {...commonProps}
            pageBlock={pageBlock}
          />
        );
      case PageBlockTypes.CIRCLE_RIGHT_IMAGE:
        return (
          <CircleImageWithBodyBlock
            key={pageBlock.id}
            flipped
            {...commonProps}
            pageBlock={pageBlock}
          />
        );
      case PageBlockTypes.ROUNDED_LEFT_IMAGE_RIGHT_BODY:
        return (
          <RoundedImageWithBodyBlock
            key={pageBlock.id}
            pageBlock={pageBlock}
            {...commonProps}
          />
        );
      case PageBlockTypes.ROUNDED_RIGHT_IMAGE_LEFT_BODY:
        return (
          <RoundedImageWithBodyBlock
            key={pageBlock.id}
            flipped
            pageBlock={pageBlock}
            {...commonProps}
          />
        );
      case PageBlockTypes.QUOTE_ON_MEDIA:
        if (!isInstanceOfPageBlock) {
          return defaultBlock(pageBlock);
        }
        return (
          <QuoteOnMediaBlock
            key={pageBlock.id}
            pageBlock={pageBlock}
            {...commonProps}
          />
        );
      case PageBlockTypes.SIMPLE_CARD:
        return (
          <SimpleCardBlock
            key={pageBlock.id}
            pageBlock={pageBlock}
            {...commonProps}
          />
        );
      case PageBlockTypes.CROSS_REFERENCE_BANNER:
        return (
          <CrossReferenceBannerBlock
            key={pageBlock.id}
            pageBlock={pageBlock}
            {...commonProps}
          />
        );
      case PageBlockTypes.LEAVE_FESTIVE_SUPPORT_MESSAGE:
        if (!isInstanceOfPageBlock) {
          return defaultBlock(pageBlock);
        }
        return (
          <LeaveFestiveSupportMessageBlock
            key={pageBlock.id}
            pageBlock={pageBlock}
            {...commonProps}
          />
        );
      case PageBlockTypes.GIFT_GIVING_CAMPAIGN_BLOCK:
        if (!isInstanceOfPageBlock) {
          return defaultBlock(pageBlock);
        }
        return (
          <GiftGivingCampaignBlock
            key={pageBlock.id}
            pageBlock={pageBlock}
            {...commonProps}
          />
        );
      case PageBlockTypes.MESSAGE_CAROUSEL:
        return (
          <MessageCarouselBlock
            key={pageBlock.id}
            title={pageBlock.heading}
            body={pageBlock.body}
            links={pageBlock.related_links}
            fluid={pageBlock.fluid_container_ind}
            xmasMessages={xmasMessages as IThankYouMessage[]}
            {...commonProps}
          />
        );
      case PageBlockTypes.ANIMATED_TREE:
        return <AnimatedTreeBlock body={pageBlock.body} />;
      default:
        return defaultBlock(pageBlock);
    }
  });
};

export default renderBlocks;
