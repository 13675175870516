import React from 'react';

import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import SpeechBubble from 'components/SpeechBubble/SpeechBubble';
import { IThankYouMessage } from 'models/thankYouMessage';

import MessageContent from './MessageContent';

const SLIDER_SETTINGS = {
  autoPlay: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
};

const SETTING_HORIZONTAL = {
  centerMode: true,
  responsive: [
    {
      breakpoint: 1680,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1320,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 880,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SETTING_VERTICAL = {
  vertical: true,
  verticalSwiping: true,
};

interface IMessageSliderProps {
  availableMessages: IThankYouMessage[];
  vertical?: boolean;
}

const MessageSlider: React.FC<IMessageSliderProps> = ({
  availableMessages,
  vertical,
}) => {
  return (
    <DynamicSlider
      {...SLIDER_SETTINGS}
      {...(vertical ? SETTING_VERTICAL : SETTING_HORIZONTAL)}
    >
      {availableMessages.map((message: IThankYouMessage) => (
        <div key={message.id} className="p-2 mb-4">
          <SpeechBubble
            cardProps={{ style: { height: '220px', overflow: 'auto' } }}
            className="supportive-message-speech-bubble"
          >
            <MessageContent supportiveMessage={message} />
          </SpeechBubble>
        </div>
      ))}
    </DynamicSlider>
  );
};

export default MessageSlider;
