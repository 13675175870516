import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import cx from 'classnames';

import s from './Paginate.module.scss';

const Paginate: React.FC<
  ReactPaginateProps & {
    pageCount?: number;
    pageRangeDisplayed?: number;
    marginPagesDisplayed?: number;
  }
> = ({
  breakClassName,
  containerClassName,
  pageLinkClassName,
  previousLinkClassName,
  nextLinkClassName,
  activeLinkClassName,
  disabledClassName,
  ...props
}) => {
  return (
    <ReactPaginate
      {...props}
      breakClassName={cx(
        breakClassName,
        s.break,
        'px-2 py-1 text-black font-weight-semi-bold',
      )}
      disabledClassName={cx(disabledClassName, s.disabled)}
      containerClassName={cx(
        containerClassName,
        s.container,
        'px-2 py-1 text-black font-weight-semi-bold',
      )}
      pageLinkClassName={cx(
        pageLinkClassName,
        s.pageLink,
        'px-2 py-1 text-black font-weight-semi-bold',
      )}
      previousLinkClassName={cx(
        previousLinkClassName,
        s.previousLink,
        'px-2 py-1 text-black font-weight-semi-bold',
      )}
      nextLinkClassName={cx(
        nextLinkClassName,
        s.nextLink,
        'px-2 py-1 text-black font-weight-semi-bold',
      )}
      activeLinkClassName={cx(
        activeLinkClassName,
        s.activeLink,
        'px-2 py-1 text-black font-weight-semi-bold text-decoration-none',
      )}
    />
  );
};

export default Paginate;
