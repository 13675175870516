import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import cx from 'classnames';

import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import ShareButtons from 'components/SocialMedia/ShareButtons';
import SpeechBubble from 'components/SpeechBubble/SpeechBubble';
import { ILink } from 'models/link';
import { IThankYouMessage } from 'models/thankYouMessage';
import { fetcher } from 'services/httpRequestor';
import { ThankYouMessageService } from 'services/thankYouMessageService';

import MessageContent from './MessageContent';
import MessageSlider from './MessageSlider';

import s from './MessageCarouselBlock.module.scss';

const messageService = new ThankYouMessageService(fetcher());

type MessageCarouselBlockProps = {
  fluid?: boolean;
  title?: string;
  body?: string;
  links?: ILink[];
  xmasMessages?: IThankYouMessage[];
  className?: string;
  noPadding?: boolean;
};
const MessageCarouselBlock: React.FC<MessageCarouselBlockProps> = ({
  fluid,
  title,
  body,
  links = [],
  xmasMessages = [],
  className,
  ...props
}) => {
  const {
    query: { slug },
  } = useRouter();

  let host;
  if (process.browser) {
    host = `${window.location.protocol}//${window.location.host}`;
  }

  const [availableMessages, setAvailableMessages] =
    useState<IThankYouMessage[]>(xmasMessages);
  const [allMessages, setAllMessages] = useState<IThankYouMessage[]>(
    xmasMessages.slice(0, 8),
  );
  const [pageIndex, setPageIndex] = useState(0);
  const [displayLoadMoreButton, setDisplayLoadMoreButton] = useState(
    xmasMessages.length > 8,
  );

  useEffect(() => {
    messageService.getCustomerLastPendingMessage().then(res => {
      const message = res?.data;
      if (message) {
        setAvailableMessages([message, ...xmasMessages]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = async () => {
    const { data: newMessages } = await messageService.getMoreMessages(
      8,
      pageIndex + 1,
    );
    setPageIndex(pageIndex + 1);

    if (newMessages?.length) {
      setAllMessages([...allMessages, ...newMessages]);
    }

    if (newMessages?.length < 8) {
      setDisplayLoadMoreButton(false);
    }
  };

  return (
    <>
      <SectionWithContainer
        {...props}
        noPadding
        className={cx(
          'bg-primary text-white pt-3',
          s['supportive-messages-block'],
          className,
        )}
        containerProps={{ fluid }}
      >
        <a href="#messages" className="d-none d-lg-block text-right mr-5">
          <u className="text-white">Want to see all the messages?</u>
        </a>

        <div className="mx-n2 d-none d-md-block">
          <MessageSlider availableMessages={availableMessages} />
        </div>
        <div className="mx-n2 d-block d-md-none">
          <MessageSlider availableMessages={availableMessages} vertical />
        </div>

        <Container className="py-4 py-lg-5 text-center">
          {title && (
            <RowWithOffsetCol className="mb-5  mx-auto">
              <h2 className={cx('heading heading-danger', s.heading)}>
                {title}
              </h2>
            </RowWithOffsetCol>
          )}
          {body && (
            <RowWithOffsetCol>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </RowWithOffsetCol>
          )}
          <div className="mt-3 align-item-center">
            {links?.map(link => (
              <Link key={link.id} href={link.link || '/'}>
                <Button
                  variant="danger"
                  className={cx(
                    'font-weight-normal mx-auto mr-md-3 mb-3 d-block d-md-inline-block',
                    s.button,
                  )}
                >
                  {link.title}
                </Button>
              </Link>
            ))}
          </div>

          <h4 className="mt-5 mb-3">
            Share our giving tree with your friends!
          </h4>
          <ShareButtons
            className="text-white"
            quote="Share the festive tree"
            color="var(--secondary)"
            url={`${host}/${slug}`}
            customTitle
          />
        </Container>
      </SectionWithContainer>

      <SectionWithContainer id="messages" className="pt-3 d-none d-lg-block">
        <h2 className="heading heading-danger">All messages</h2>

        <Row>
          {allMessages.map(message => (
            <Col key={message.id} md={6} className="mb-4">
              <SpeechBubble cardProps={{ style: { height: '220px' } }}>
                <MessageContent supportiveMessage={message} />
              </SpeechBubble>
            </Col>
          ))}
        </Row>

        {displayLoadMoreButton && (
          <div className="mt-4 text-center">
            <Button variant="danger" onClick={handleLoadMore}>
              Load More
            </Button>
          </div>
        )}
      </SectionWithContainer>
    </>
  );
};

export default MessageCarouselBlock;
