import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { useApp } from 'contexts/appContext';
import BaubleTypes from 'models/baubleTypes';
import DonationCampaignTypes from 'models/donationCampaignTypes';

import s from './AnimatedTreeBlock.module.scss';

type AnimatedTreeBlockProps = {
  body?: string;
};

const AnimatedTreeBlock: React.FC<AnimatedTreeBlockProps> = ({ body }) => {
  const router = useRouter();
  const {
    query: { decoration, option },
  } = router;
  const { animationsS3Url } = useApp();

  const animationUrl = useMemo(() => {
    if (decoration === DonationCampaignTypes.FestiveBauble.replace(/\s/g, '')) {
      if (option === BaubleTypes.Red) {
        return `${animationsS3Url}/xmas_tree_red.mp4`;
      }
      if (option === BaubleTypes.Yellow) {
        return `${animationsS3Url}/xmas_tree_yellow.mp4`;
      }
      return `${animationsS3Url}/xmas_tree_blue.mp4`;
    }

    return `${animationsS3Url}/xmas_tree.mp4`;
  }, [animationsS3Url, decoration, option]);

  const showStaticTree = isEmpty(router.query.decoration);

  return (
    <div className={cx('mx-auto w-100', s.root)}>
      {showStaticTree ? (
        <>
          {body && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: body }}
              className="mb-4 mx-3"
            />
          )}
          <img
            loading="lazy"
            src="/imgs/static_tree.jpg"
            alt="animated xmas tree"
            width="100%"
          />
        </>
      ) : (
        <video width="100%" autoPlay muted loop playsInline>
          <source src={animationUrl} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      )}
    </div>
  );
};
export default AnimatedTreeBlock;
