import React from 'react';

import HorizontalRoundedCornerContent from 'components/HorizontalRoundedCornerContent/HorizontalRoundedCornerContent';
import PageBlockBodyContent from 'components/PageBlocks/PageBlockBodyContent/PageBlockBodyContent';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { IArticleBlock } from 'models/articleBlock';
import { IPageBlock } from 'models/pageBlock';

interface IProps {
  pageBlock: IPageBlock | IArticleBlock;
  flipped?: boolean;
  fluid?: boolean;
}
const RoundedImageWithBodyBlock: React.FC<IProps> = ({
  pageBlock,
  flipped,
  fluid,
  ...props
}) => {
  const colourSchema = pageBlock.colour_schema?.name || 'primary';
  const { btnColor, bgColor, headingColor, textColor, hubspotTheme } =
    colourSchema === 'secondary'
      ? {
          bgColor: 'secondary',
          headingColor: 'danger',
          textColor: 'black',
          btnColor: 'primary',
          hubspotTheme: undefined,
        }
      : {
          bgColor: colourSchema,
          headingColor: 'secondary',
          textColor: 'white',
          btnColor: 'danger',
          hubspotTheme: 'dark',
        };

  const imageProps =
    colourSchema === 'gos-scheme'
      ? {
          imageOnTop: '/imgs/guardians-of-surf.svg',
          imageOnTopAlt: 'GOS',
        }
      : {};

  const contents = [
    <HorizontalRoundedCornerContent.Col
      key="image"
      imgURL={pageBlock.image?.url}
    />,
    <HorizontalRoundedCornerContent.Col
      key="content"
      className={`bg-${bgColor} text-${textColor}`}
    >
      <PageBlockBodyContent
        {...imageProps}
        headingProps={{ className: `heading heading-${headingColor}` }}
        hubspotTheme={(hubspotTheme as 'dark') || undefined}
        relatedLinksButtonProps={{ variant: btnColor }}
        pageBlock={pageBlock}
      />
    </HorizontalRoundedCornerContent.Col>,
  ];
  return (
    <SectionWithContainer {...props} containerProps={{ fluid }}>
      <HorizontalRoundedCornerContent>
        {flipped ? contents.reverse() : contents}
      </HorizontalRoundedCornerContent>
    </SectionWithContainer>
  );
};

export default RoundedImageWithBodyBlock;
