import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import cx from 'classnames';

import s from './HalfPageContentWithContainer.module.scss';

interface IProps {
  className?: string;
  flipped?: boolean;
  halfPageContent?: JSX.Element;
}
const HalfPageContentWithContainer: React.FC<IProps> = ({
  className,
  halfPageContent,
  flipped,
  children,
  ...props
}) => {
  return (
    <section className={cx(className, 'position-relative')} {...props}>
      {halfPageContent && (
        <div
          className={cx(s['half-page-content'], {
            'left-0': flipped,
            'right-0': !flipped,
          })}
        >
          {halfPageContent}
        </div>
      )}
      <Container className="position-relative z-100">
        <Row className={cx({ 'justify-content-end': flipped })}>
          <Col className="px-4" md={6}>
            {children}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HalfPageContentWithContainer;
