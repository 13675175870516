enum PageBlockTypes {
  CUSTOM = 'Custom',
  INVESTOR_BOARD_MEMBERS = 'Investor Board Members',
  BOARD_MEMBERS = 'Board Members',
  ARTICLES_LIST_AND_SEARCH = 'Articles List And Search',
  STATISTIC_CARDS = 'Statistic Cards',
  RELATED_FAQS = 'Related FAQs',
  VERTICAL_TILES = 'Vertical Tiles',
  LOTTERY_WIDGET = 'Lottery Widget',
  SUPPORTIVE_MESSAGES = 'Supportive messages',
  CURRENT_APPEALS_COMPACT = 'Current Appeals Compact',
  CURRENT_APPEALS = 'Current Appeals',
  REGULAR_DONATION = 'Regular Donation',
  DONATION_WIDGET = 'Donation Widget',
  GOS_STATUS = 'GOS Status',
  RELATED_LINKS = 'Related Links',
  RELATED_BUTTONS = 'Related Buttons',
  NEWSLETTER_SIGN_UP = 'Newsletter Sign-up',
  GOS_FEATURED_BLOCK = 'GOS Featured',
  YELLOW_CIRCLE_BACKGROUND = 'Yellow Circle Background',
  SQUARED_LEFT_IMAGE_RIGHT_BODY = 'Squared Left Image Right Body',
  SQUARED_RIGHT_IMAGE_LEFT_BODY = 'Squared Right Image Left Body',
  CIRCLE_LEFT_IMAGE = 'Circle Left Image',
  CIRCLE_RIGHT_IMAGE = 'Circle Right Image',
  QUOTE_ON_MEDIA = 'Quote On Media',
  ROUNDED_LEFT_IMAGE_RIGHT_BODY = 'Rounded Left Image Right Body',
  ROUNDED_RIGHT_IMAGE_LEFT_BODY = 'Rounded Right Image Left Body',
  CROSS_REFERENCE_BANNER = 'Cross-Reference Banner',
  LEAVE_FESTIVE_SUPPORT_MESSAGE = 'Leave Festive Support Message',
  GIFT_GIVING_CAMPAIGN_BLOCK = 'Gift Giving Campaign',
  MESSAGE_CAROUSEL = 'Message Carousel',
  SIMPLE_CARD = 'Simple Card',
  ANIMATED_TREE = 'Animated Tree',
}
export default PageBlockTypes;
