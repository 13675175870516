import React from 'react';

import PageBlockBodyContent from 'components/PageBlocks/PageBlockBodyContent/PageBlockBodyContent';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { IArticleBlock } from 'models/articleBlock';
import { IPageBlock } from 'models/pageBlock';

interface IProps {
  pageBlock: IPageBlock | IArticleBlock;
}

const CustomBlock: React.FC<IProps> = ({ pageBlock, ...props }) => {
  const imageProps = {
    imageOnTop: pageBlock.image?.url,
    imageOnTopAlt: pageBlock.image_alt,
  };

  return (
    <SectionWithContainer
      key={pageBlock.id}
      {...props}
      containerProps={{ fluid: pageBlock.fluid_container_ind }}
    >
      <PageBlockBodyContent
        withOffset
        headingProps={{ className: 'heading heading-danger' }}
        pageBlock={pageBlock}
        {...imageProps}
      />
    </SectionWithContainer>
  );
};

export default CustomBlock;
