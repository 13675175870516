import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';

import cx from 'classnames';
import captureExceptionForClientSide from 'utils/captureExceptionForClientSide';

import DonateTodayWithSurfLifeSaverAvatar from 'components/DonateTodayWithSurfLifeSaverAvatar/DonateTodayWithSurfLifeSaverAvatar';
import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import MessageOfSupportForm from 'components/MessageOfSupportForm/MessageOfSupportForm';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import SpeechBubble from 'components/SpeechBubble/SpeechBubble';
import { useApp } from 'contexts/appContext';
import { useRecaptchaContext } from 'contexts/RecaptchaContext';
import { IDonationCampaign } from 'models/donationCampaign';
import { IThankYouMessage } from 'models/thankYouMessage';
import { fetcher } from 'services/httpRequestor';
import { ThankYouMessageService } from 'services/thankYouMessageService';

import s from './SupportiveMessagesBlock.module.scss';

const SLIDER_SETTINGS = {
  autoPlay: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 2,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  arrows: false,
};

type SupportiveMessagesBlockProps = {
  fluid?: boolean;
  supportiveMessages?: IThankYouMessage[];
  donationCampaign?: IDonationCampaign;
  className?: string;
  noPadding?: boolean;
};
const SupportiveMessagesBlock: React.FC<SupportiveMessagesBlockProps> = ({
  fluid,
  donationCampaign,
  supportiveMessages,
  className,
  ...props
}) => {
  const { featuredDonationCampaignSlug } = useApp();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { getRecaptchaToken } = useRecaptchaContext();

  const handleSubmit = useCallback(
    async (data: any) => {
      setLoading(true);
      setSubmitted(false);
      setError(undefined);
      try {
        const recaptchaToken = await getRecaptchaToken();
        const thankYouMessageService = new ThankYouMessageService(fetcher());
        await thankYouMessageService.create(data, {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        });
        setSubmitted(true);
      } catch (err: any) {
        setError(err);
        captureExceptionForClientSide(err, {
          extra: {
            data,
          },
        });
        console.error(err);
      }
      setLoading(false);
    },
    [getRecaptchaToken],
  );
  return (
    <SectionWithContainer
      {...props}
      className={cx('bg-light-grey', s['supportive-messages-block'], className)}
      containerProps={{ fluid }}
    >
      <RowWithOffsetCol>
        <Row className="mb-5">
          <Col lg={7}>
            <h2 className="heading heading-danger">
              <span>Leave a message of support for your surf lifesavers.</span>
            </h2>
          </Col>
        </Row>
      </RowWithOffsetCol>
      <Row>
        <Col
          className={cx({ 'offset-lg-3': !supportiveMessages?.length })}
          lg={5}
        >
          {error && (
            <Alert variant="danger">
              <Alert.Heading>Error</Alert.Heading>
              <p>Something happened in your request</p>
            </Alert>
          )}
          {submitted ? (
            <h3 className="text-primary mb-5">
              Thank you for your message of support!
            </h3>
          ) : (
            <MessageOfSupportForm
              formControlProps={{ className: 'bg-white' }}
              loading={loading}
              onSubmit={handleSubmit}
            />
          )}
        </Col>
        {!!supportiveMessages?.length && (
          <Col lg={7}>
            <div className="mx-n2">
              <DynamicSlider {...SLIDER_SETTINGS}>
                {supportiveMessages.map(
                  (supportiveMessage: IThankYouMessage) => (
                    <div key={supportiveMessage.id} className="px-4 py-2">
                      <SpeechBubble
                        cardProps={{ style: { height: '180px' } }}
                        className="supportive-message-speech-bubble"
                      >
                        <SpeechBubble.Body className="px-4">
                          <h3 className="text-primary mb-2">
                            {supportiveMessage.customer?.first_name} says...
                          </h3>
                          <p className="font-italic">
                            {supportiveMessage.message}
                          </p>
                        </SpeechBubble.Body>
                      </SpeechBubble>
                    </div>
                  ),
                )}
              </DynamicSlider>
            </div>
          </Col>
        )}
      </Row>
      <RowWithOffsetCol
        className="mt-4"
        offset={3}
        colProps={{ className: 'd-flex' }}
      >
        <DonateTodayWithSurfLifeSaverAvatar
          onDonateToday={() => {
            const slug = donationCampaign?.slug || featuredDonationCampaignSlug;
            router
              .push(
                {
                  pathname: '/campaign/[slug]',
                  query: {
                    slug,
                  },
                },
                `/campaign/${slug}`,
              )
              .then(() => window.scrollTo(0, 0));
          }}
        />
      </RowWithOffsetCol>
    </SectionWithContainer>
  );
};

export default SupportiveMessagesBlock;
