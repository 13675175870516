import React, { HTMLAttributes } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { AiOutlineCloudDownload } from 'react-icons/ai';

import cx from 'classnames';
import { instanceOfPageBlock } from 'utils/checkInterface';
import renderBlockContents from 'utils/renderBlockContents';

import HubspotFormWithStyle from 'components/HubspotFormWithStyle/HubspotFormWithStyle';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import { IArticleBlock } from 'models/articleBlock';
import { ILink } from 'models/link';
import { IPageBlock } from 'models/pageBlock';

import s from './PageBlockBodyContent.module.scss';

interface IProps {
  pageBlock: IPageBlock | IArticleBlock;
  imageOnTopAlt?: string;
  imageOnTop?: string;
  hubspotTheme?: 'dark' | undefined;
  headingProps?: HTMLAttributes<HTMLElement>;
  bodyProps?: HTMLAttributes<HTMLElement>;
  relatedLinksButtonIconColor?: string;
  relatedLinksButtonProps?: ButtonProps;
  withOffset?: boolean;
  hideHeading?: boolean;
}

const PageBlockBodyContent: React.FC<IProps> = ({
  imageOnTopAlt,
  imageOnTop,
  hubspotTheme,
  pageBlock,
  headingProps,
  bodyProps,
  relatedLinksButtonIconColor,
  relatedLinksButtonProps,
  withOffset,
  hideHeading,
}) => {
  const isInstanceOfPageBlock = instanceOfPageBlock(pageBlock);

  const renderHeading = () => (
    <h2 {...(headingProps || {})}>{pageBlock.heading}</h2>
  );
  const renderBody = (body: string) =>
    pageBlock.body && (
      <div
        {...(bodyProps || {})}
        className={cx(s['page-block-body'], bodyProps?.className)}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    );
  const renderRelatedLinks = (relatedLinks: ILink[]) =>
    relatedLinks.map((link: ILink) => (
      <Button
        {...(relatedLinksButtonProps || {})}
        key={link.id}
        as="a"
        className={cx(
          `font-weight-normal d-inline-flex align-items-center col-12 col-sm-auto mr-3 mb-3 flex-grow-0`,
          relatedLinksButtonProps?.className,
        )}
        target={link.file?.url ? '_blank' : '_self'}
        href={link.file?.url || link.link}
      >
        <div className="d-flex align-items-center">
          {link.file?.url && (
            <AiOutlineCloudDownload
              color={relatedLinksButtonIconColor || 'white'}
              className="mr-2"
              size={20}
            />
          )}
          <div data-content="page_page_block_link">{link.title}</div>
        </div>
      </Button>
    ));

  return (
    <>
      {imageOnTop && (
        <img
          loading="lazy"
          src={imageOnTop}
          alt={imageOnTopAlt}
          className="mb-4"
        />
      )}
      {pageBlock.heading &&
        !hideHeading &&
        (withOffset ? (
          <RowWithOffsetCol className="mb-5">
            {renderHeading()}
          </RowWithOffsetCol>
        ) : (
          <div className="mb-5">{renderHeading()}</div>
        ))}
      {pageBlock.body &&
        (withOffset ? (
          <RowWithOffsetCol>{renderBody(pageBlock.body)}</RowWithOffsetCol>
        ) : (
          renderBody(pageBlock.body)
        ))}
      {isInstanceOfPageBlock && !!pageBlock.page_block_contents?.length && (
        <div className={cx({ 'mt-5': pageBlock.heading || pageBlock.body })}>
          {renderBlockContents(pageBlock.page_block_contents)}
        </div>
      )}
      {!isInstanceOfPageBlock && !!pageBlock.article_block_contents?.length && (
        <div className={cx({ 'mt-5': pageBlock.heading || pageBlock.body })}>
          {renderBlockContents(pageBlock.article_block_contents)}
        </div>
      )}
      {isInstanceOfPageBlock && pageBlock.hubspot_form?.form_id && (
        <div className="mt-5">
          <HubspotFormWithStyle
            theme={hubspotTheme}
            formId={pageBlock.hubspot_form?.form_id}
            loading={<div>Loading...</div>}
          />
        </div>
      )}
      {!!pageBlock.related_links?.length &&
        (withOffset ? (
          <RowWithOffsetCol className="mt-5 d-flex">
            {renderRelatedLinks(pageBlock.related_links)}
          </RowWithOffsetCol>
        ) : (
          <div className="mt-5 mb-n3 d-flex">
            {renderRelatedLinks(pageBlock.related_links)}
          </div>
        ))}
    </>
  );
};
export default PageBlockBodyContent;
