import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { uniqBy } from 'lodash';

import ArticlesList from 'components/ArticlesList/ArticlesList';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import Paginate from 'components/Paginate/Paginate';
import { IArticle } from 'models/article';
import { IArticleCategory } from 'models/articleTag';
import { ArticleClientService } from 'services/articleClientService';
import { fetcher } from 'services/httpRequestor';

interface ArticlesListAndSearchBlockProps {
  initialArticles?: IArticle[];
  fluid?: boolean;
  articlesPerPage: number;
  className?: string;
  noPadding?: boolean;
}
const ArticlesListAndSearchBlock: React.FC<ArticlesListAndSearchBlockProps> = ({
  fluid,
  initialArticles,
  articlesPerPage,
  ...props
}) => {
  const articleService = useMemo(() => new ArticleClientService(fetcher()), []);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { category_id: '' },
  });
  const [articles, setArticles] = useState<IArticle[]>(initialArticles || []);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalArticles, setTotalArticles] = useState(
    initialArticles?.length || 0,
  );

  const totalPages = totalArticles
    ? Math.ceil(totalArticles / articlesPerPage)
    : 0;

  const fetchArticles = useCallback(
    (category_id?: number | string): Promise<IArticle[]> => {
      setLoading(true);
      return articleService
        .getAllArticles({
          query: {
            category_id,
          },
        })
        .then(response => {
          setLoading(false);
          return response;
        })
        .catch(err => {
          setLoading(false);
          throw err;
        });
    },
    [articleService],
  );

  const handleFilter = async ({ category_id }: { category_id: string }) => {
    if (loading) {
      return;
    }
    setArticles([]);
    setCurrentPage(0);
    const newArticles = await fetchArticles(category_id);
    setArticles(newArticles);
    setTotalArticles(newArticles.length);
  };

  const handleTagClick = (category: IArticleCategory) => {
    setValue('category_id', category.id.toString());
    fetchArticles(category.id);
  };

  const handlePageClick = async ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const start = currentPage * articlesPerPage;
  const end = start + articlesPerPage;

  const [allCategories, setAllCategories] = useState<IArticleCategory[]>([]);

  const getCurrentSiteCategories = useCallback(async () => {
    const newArticles = await fetchArticles();

    const res = newArticles.reduce((acc, cur) => {
      const category = cur.categories?.length && cur.categories;

      if (category) {
        return [...acc, ...category];
      }
      return acc;
    }, [] as IArticleCategory[]);

    setAllCategories(uniqBy(res, 'id'));
  }, [fetchArticles]);

  useEffect(() => {
    getCurrentSiteCategories();
  }, [getCurrentSiteCategories]);

  return (
    <SectionWithContainer {...props} containerProps={{ fluid }}>
      {!!allCategories?.length && (
        <Form
          onSubmit={handleSubmit(handleFilter)}
          className="mb-3 p-0 px-lg-8"
        >
          <Row className="m-0">
            <Col
              className="min-w-auto flex-grow-0 flex-shrink-0 d-flex justify-content-center flex-column mb-2"
              xs={12}
              md="auto"
            >
              <h3 className="white-space-nowrap">Filter Articles</h3>
            </Col>
            <Col xs={12} md="auto" className="mb-2 flex-grow-1">
              <Form.Control ref={register()} as="select" name="category_id">
                <option value="">All</option>
                {allCategories.map(tag => (
                  <option key={tag.id} value={tag.id}>
                    {tag.name}
                  </option>
                ))}
              </Form.Control>
            </Col>
            <Col className="min-w-auto flex-grow-0 mb-2" xs={12} md="auto">
              <Button type="submit">Search</Button>
            </Col>
          </Row>
        </Form>
      )}
      <ArticlesList
        className="justify-content-center"
        loading={loading}
        articles={articles.slice(start, end)}
        onTagClick={handleTagClick}
      />
      {totalPages > 1 && (
        <div className="d-flex justify-content-center">
          <Paginate
            initialPage={currentPage}
            pageCount={totalPages}
            onPageChange={handlePageClick}
            hrefBuilder={(pageIndex: number) => `#${pageIndex}`}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
          />
        </div>
      )}
    </SectionWithContainer>
  );
};

export default ArticlesListAndSearchBlock;
