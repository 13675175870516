import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { AiOutlineCloudDownload } from 'react-icons/ai';

import cx from 'classnames';

import Circle from 'components/Circle/Circle';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { IArticleBlock } from 'models/articleBlock';
import { ILink } from 'models/link';
import { IPageBlock } from 'models/pageBlock';

import s from './RelatedLinksBlock.module.scss';

interface IProps {
  pageBlock: IPageBlock | IArticleBlock;
  variant?: string;
  className?: string;
  noPadding?: boolean;
}
const RelatedLinksBlock: React.FC<IProps> = ({
  pageBlock,
  variant,
  ...props
}) => {
  if (!pageBlock.related_links?.length) {
    return <h1>There is no links</h1>;
  }
  const [
    btnVariant,
    circleVariant,
    btnSize,
    textDecoration,
    withCircle,
    iconColor,
  ] =
    variant === 'links'
      ? ['light-grey', 'secondary', 'sm', 'underline', true, 'black']
      : ['primary', undefined, undefined, 'none', false, 'white'];
  return (
    <SectionWithContainer
      {...props}
      containerProps={{ fluid: pageBlock.fluid_container_ind }}
    >
      <Row>
        {pageBlock.heading && (
          <Col md={6} lg={4} className="mb-4 mb-md-0">
            <h3
              className="heading heading-danger"
              data-content="page_page_block_heading"
            >
              {pageBlock.heading}
            </h3>
            {pageBlock.body && (
              <div dangerouslySetInnerHTML={{ __html: pageBlock.body }} />
            )}
          </Col>
        )}
        <Col
          md={pageBlock.heading ? 6 : 'auto'}
          lg={pageBlock.heading ? 8 : 'auto'}
        >
          <Row>
            {pageBlock.related_links.map((link: ILink) => (
              <Col
                key={link.id}
                className="min-w-auto flex-grow-0 d-flex flex-column col-12 col-sm-auto mb-2 align-items-center"
              >
                <Button
                  as="a"
                  size={btnSize as 'sm' | undefined}
                  variant={btnVariant}
                  className={cx(
                    `font-weight-normal text-decoration-${textDecoration} p-3 d-inline-flex align-items-center flex-grow-1 col-12 col-sm-auto`,
                    s.button,
                  )}
                  target="_blank"
                  href={link.file?.url || link.link}
                >
                  <div className="d-flex align-items-center">
                    {link.file?.url && withCircle && (
                      <Circle
                        className={`bg-${circleVariant} d-flex align-items-center justify-content-center mr-2 no-shadow flex-shrink-0`}
                        size="xs"
                      >
                        <AiOutlineCloudDownload color={iconColor} />
                      </Circle>
                    )}
                    {link.file?.url && !withCircle && (
                      <AiOutlineCloudDownload color={iconColor} />
                    )}
                    <div data-content="page_page_block_link">{link.title}</div>
                  </div>
                </Button>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </SectionWithContainer>
  );
};

export default RelatedLinksBlock;
