import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import cx from 'classnames';

import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import { IGOSStatus } from 'models/gosStatus';

import s from './GOSStatusBlock.module.scss';

interface IProps {
  gosStatus?: IGOSStatus[];
  fluid?: boolean;
  className?: string;
}
const GOSStatusBlock: React.FC<IProps> = ({ fluid, gosStatus, className }) => {
  const [selectedGOSStatusIndex, setSelectedGOSStatusIndex] = useState(0);

  if (!gosStatus?.length) {
    return <h1>There is no board GOS Status</h1>;
  }

  const selectedGOSStatus = gosStatus[selectedGOSStatusIndex];
  const SLIDER_SETTINGS = {
    dots: false,
    arrows: false,
    autoplay: false,
    infinite: false,
    centered: true,
    centerMode: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
    responsive: [
      // {
      //   breakpoint: 845,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //   },
      // },
      // {
      //   breakpoint: 580,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  const renderGOSStatusButtons = (): JSX.Element[] => {
    return gosStatus.map((gosStatusItem: IGOSStatus, index: number) => (
      <div key={gosStatusItem.id} className="py-2 px-2 flex-column d-flex">
        <style jsx global>
          {`
            .gos-status-background.color-${gosStatusItem.id} {
              background: ${gosStatusItem.colour}20 !important;
            }
            .btn-gos-status.color-${gosStatusItem.id} {
              color: ${gosStatusItem.colour} !important;
            }
            .btn-gos-status.color-${gosStatusItem.id}.active,
              .btn-gos-status.color-${gosStatusItem.id}:hover {
              background: ${gosStatusItem.colour} !important;
              color: white !important;
              border-color: ${gosStatusItem.colour} !important;
            }
          `}
        </style>
        <Button
          variant="light-grey"
          onClick={() => setSelectedGOSStatusIndex(index)}
          className={cx(
            `text-left text-black d-block w-100 btn-shadow btn-gos-status flex-grow-1 color-${gosStatusItem.id} p-2`,
            s['gos-status-button'],
            { active: selectedGOSStatus.id === gosStatusItem.id },
          )}
        >
          <div className="d-flex align-items-center">
            <img src={gosStatusItem.tile_image.url} alt={gosStatusItem.name} />
            <strong>{gosStatusItem.name}</strong>
          </div>
        </Button>
      </div>
    ));
  };

  return (
    <>
      <div className={cx('pt-5', className)}>
        <DynamicSlider
          {...SLIDER_SETTINGS}
          className="flex-grow-1 d-block mb-n5"
        >
          {renderGOSStatusButtons()}
        </DynamicSlider>
        <SectionWithContainer
          containerProps={{ fluid }}
          className={cx(
            `bg-light-grey gos-status-background pt-6 color-${selectedGOSStatus.id}`,
            s['gos-status-section'],
          )}
        >
          <RowWithOffsetCol className="mt-5">
            <Row>
              <Col md={5} lg={3}>
                <img
                  loading="lazy"
                  src={selectedGOSStatus.block_image.url}
                  alt={selectedGOSStatus.name}
                  className={cx(
                    'overflow-hidden border-radius mb-3 mx-auto d-block',
                    s['gos-status-image'],
                  )}
                />
              </Col>
              <Col md={7} lg={9}>
                <h3 className="mb-3">
                  Donate ${selectedGOSStatus.dollar_value} per month
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedGOSStatus.brief,
                  }}
                />
              </Col>
            </Row>
          </RowWithOffsetCol>
        </SectionWithContainer>
      </div>
    </>
  );
};

export default GOSStatusBlock;
