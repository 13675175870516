import React, { HTMLAttributes } from 'react';

import cx from 'classnames';

import HalfPageContentWithContainer from 'components/HalfPageContentWithContainer/HalfPageContentWithContainer';
import PageBlockBodyContent from 'components/PageBlocks/PageBlockBodyContent/PageBlockBodyContent';
import { IArticleBlock } from 'models/articleBlock';
import { IPageBlock } from 'models/pageBlock';

interface IProps extends HTMLAttributes<HTMLElement> {
  pageBlock: IPageBlock | IArticleBlock;
  flipped?: boolean;
}
const SquaredImageWithBodyBlock: React.FC<IProps> = ({
  pageBlock,
  flipped,
  className,
}) => {
  const colourSchema = pageBlock.colour_schema?.name || 'primary';
  const {
    bgColor,
    textColor,
    headingClassName,
    relatedLinksVariant,
    hubspotTheme,
  } =
    colourSchema === 'secondary'
      ? {
          bgColor: 'secondary',
          headingClassName: 'heading heading-danger',
          textColor: '',
          relatedLinksVariant: 'primary',
          hubspotTheme: undefined,
        }
      : {
          bgColor: colourSchema,
          headingClassName: 'heading heading-secondary',
          textColor: 'white',
          relatedLinksVariant: 'danger',
          hubspotTheme: 'dark',
        };

  return (
    <HalfPageContentWithContainer
      className={cx(`my-4 my-lg-5 text-${textColor} bg-${bgColor}`, className)}
      halfPageContent={
        <img
          height="100%"
          width="100%"
          loading="lazy"
          className="flex-grow-1 object-fit-cover d-block h-100 w-100"
          src={pageBlock.image?.url}
          alt={pageBlock.image_alt}
        />
      }
      flipped={flipped}
    >
      <div className="py-5">
        <PageBlockBodyContent
          hubspotTheme={hubspotTheme as 'dark' | undefined}
          relatedLinksButtonProps={{ variant: relatedLinksVariant }}
          headingProps={{ className: headingClassName }}
          pageBlock={pageBlock}
        />
      </div>
    </HalfPageContentWithContainer>
  );
};

export default SquaredImageWithBodyBlock;
