import React, { HTMLAttributes } from 'react';
import { Col, Row } from 'react-bootstrap';

import cx from 'classnames';

import CircleAvatarOverflow from 'components/CircleAvatarOverflow/CircleAvatarOverflow';
import PageBlockBodyContent from 'components/PageBlocks/PageBlockBodyContent/PageBlockBodyContent';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import { IPageBlock } from 'models/pageBlock';

import s from './YellowCircleBackgroundBlock.module.scss';

interface LifeSaverBackgroundContentProps extends HTMLAttributes<HTMLElement> {
  pageBlock: IPageBlock;
}
const YellowCircleBackgroundBlock: React.FC<
  LifeSaverBackgroundContentProps
> = ({ pageBlock, ...props }) => {
  return (
    <SectionWithContainer {...props}>
      <RowWithOffsetCol>
        <Row
          className={cx(
            'position-relative align-items-center',
            s['life-saver-row'],
          )}
        >
          {pageBlock.image?.url && (
            <CircleAvatarOverflow
              offset={20}
              size="xxl"
              wrapperProps={{
                className: s['life-saver-circle-wrapper'],
              }}
              className={cx(
                'bg-secondary no-shadow',
                s['life-saver-circle'],
                'mb-5 mb-lg-3',
              )}
            >
              <img
                height="auto"
                width="100%"
                loading="lazy"
                className="pr-0 pr-md-9"
                src={pageBlock.image?.url}
                alt={pageBlock.image_alt}
              />
            </CircleAvatarOverflow>
          )}
          <Col lg={7} className="offset-0 offset-md-4 offset-lg-5 offset-xl-5">
            <PageBlockBodyContent
              headingProps={{ className: 'heading heading-danger' }}
              pageBlock={pageBlock}
            />
          </Col>
        </Row>
      </RowWithOffsetCol>
    </SectionWithContainer>
  );
};

export default YellowCircleBackgroundBlock;
