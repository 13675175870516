import getConfig from 'next/config';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import HubspotFormWithStyle from 'components/HubspotFormWithStyle/HubspotFormWithStyle';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import SocialMediaButtons from 'components/SocialMediaButtons/SocialMediaButtons';
import { useApp } from 'contexts/appContext';

const { publicRuntimeConfig } = getConfig();

interface IProps {
  fluid?: boolean;
  className?: string;
}
const NewsletterSignUpBlock: React.FC<IProps> = ({ fluid, className }) => {
  const {
    facebookFoundationPage,
    instagramFoundationUser,
    twitterFoundationUser,
    youtubeFoundationChannel,
  } = useApp();
  return (
    <SectionWithContainer
      className={className}
      noPadding
      containerProps={{ fluid }}
    >
      <Row className="mb-4">
        <Col lg={8}>
          <h2 className="heading heading-danger">
            Receive updates from Surf Life Saving Foundation
          </h2>
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-sm-3">
          <p>
            Receive updates on how your donation has helped Surf Life Saving,
            plus hear about real life rescue stories and exciting new ventures
            by signing up for our newsletter.
          </p>
          <HubspotFormWithStyle
            formId={publicRuntimeConfig.hubspotNewsletterFormID}
          />
          <p className="mt-4">
            See our{' '}
            <a
              href="/collection-statement"
              className="text-decoration-underline text-black"
            >
              collection policy
            </a>{' '}
            to read how we handle your personal information.
          </p>
        </Col>
        <Col lg={6}>
          <p className="mb-4">
            Follow us on our social channels. We’ll keep you up to date on…
          </p>
          <SocialMediaButtons
            facebookFoundationPage={facebookFoundationPage}
            instagramFoundationUser={instagramFoundationUser}
            twitterFoundationUser={twitterFoundationUser}
            youtubeFoundationChannel={youtubeFoundationChannel}
            buttonProps={{ variant: 'link' }}
            iconProps={{ color: 'var(--primary)', size: 40 }}
          />
        </Col>
      </Row>
    </SectionWithContainer>
  );
};

export default NewsletterSignUpBlock;
