import React, { HTMLAttributes } from 'react';
import { Col, Row } from 'react-bootstrap';

import cx from 'classnames';

import Circle from 'components/Circle/Circle';
import PageBlockBodyContent from 'components/PageBlocks/PageBlockBodyContent/PageBlockBodyContent';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { IArticleBlock } from 'models/articleBlock';
import { IPageBlock } from 'models/pageBlock';

import s from './CircleImageWithBodyBlock.module.scss';

interface IProps extends HTMLAttributes<HTMLElement> {
  pageBlock: IPageBlock | IArticleBlock;
  flipped?: boolean;
}
const CircleImageWithBodyBlock: React.FC<IProps> = ({
  pageBlock,
  flipped,
  ...props
}) => {
  const contents = [
    ...(pageBlock.image
      ? [
          <Col
            key="circle"
            className="flex-grow-1 min-w-auto flex-md-grow-0 col-12 col-md-auto py-3"
          >
            <Circle size="xl" className="m-auto">
              <img
                loading="lazy"
                src={pageBlock.image?.url}
                alt={pageBlock.image_alt}
              />
            </Circle>
          </Col>,
        ]
      : []),
    <Col key="body" className="flex-grow-1 py-3 ">
      <div>
        <PageBlockBodyContent
          relatedLinksButtonProps={{
            variant: 'light-grey',
            size: 'sm',
            className: s['link-buttons'],
          }}
          relatedLinksButtonIconColor="black"
          headingProps={{ className: 'heading heading-danger' }}
          pageBlock={pageBlock}
        />
      </div>
    </Col>,
  ];
  const renderContents = () => {
    if (contents.length > 1) {
      return (
        <Row>
          <Col lg={11} className={cx({ 'offset-0 offset-lg-1': flipped })}>
            <Row className={cx({ 'flex-column-reverse flex-md-row': flipped })}>
              {flipped ? contents.reverse() : contents}
            </Row>{' '}
          </Col>
        </Row>
      );
    }
    return contents;
  };
  return (
    <SectionWithContainer {...props}>{renderContents()}</SectionWithContainer>
  );
};

export default CircleImageWithBodyBlock;
