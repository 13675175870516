import React from 'react';
import { Button } from 'react-bootstrap';

import cx from 'classnames';

import HubspotFormWithStyle from 'components/HubspotFormWithStyle/HubspotFormWithStyle';
import { IFileProperties } from 'models/fileProperties';
import { HeadingSize } from 'models/headingSize';

interface PageBlockContentBodyProps {
  headingProps?: any;
  btnProps?: any;
  bodyProps?: any;
  heading: string;
  headingSize?: HeadingSize;
  blockBody?: string;
  link?: string;
  linkLabel?: string;
  downloadFile?: IFileProperties;
  hubspotFormID?: string;
  hubspotTheme?: string;
  imageOnTop?: string;
  imageOnTopAlt?: string;
}
const PageBlockContentBody: React.FC<PageBlockContentBodyProps> = ({
  imageOnTopAlt,
  imageOnTop,
  headingProps,
  btnProps,
  bodyProps,
  heading,
  headingSize,
  blockBody,
  link,
  linkLabel,
  downloadFile,
  hubspotFormID,
  hubspotTheme,
}) => {
  const hasLink = link && linkLabel;
  const renderHeading = () => {
    if (!heading) {
      return undefined;
    }
    const commonHeadingProps = {
      'data-content': 'page_content_heading',
      ...headingProps,
    };

    switch (headingSize) {
      case HeadingSize.BIG:
        return (
          <h1
            {...{
              ...commonHeadingProps,
              className: cx('my-0', commonHeadingProps.className),
            }}
          >
            {heading}
          </h1>
        );
      case HeadingSize.SMALL:
        return (
          <h3
            {...{
              ...commonHeadingProps,
              className: cx('my-0', commonHeadingProps.className),
            }}
          >
            {heading}
          </h3>
        );
      default:
        return (
          <h2
            {...{
              ...commonHeadingProps,
              className: cx('my-0', commonHeadingProps.className),
            }}
          >
            {heading}
          </h2>
        );
    }
  };
  return (
    <>
      {imageOnTop && (
        <img
          loading="lazy"
          src={imageOnTop}
          alt={imageOnTopAlt}
          className="mb-4"
        />
      )}
      {renderHeading()}
      {blockBody && (
        <div
          {...{
            ...bodyProps,
            className: cx(bodyProps?.className, { 'mt-4': heading }),
          }}
          data-content="page_content_body"
          dangerouslySetInnerHTML={{ __html: blockBody }}
        />
      )}
      <div className="flex-column d-flex align-items-start w-75 align-self-center">
        {hasLink && (
          <Button
            {...btnProps}
            variant={btnProps?.variant}
            className={cx('mt-3', btnProps?.className)}
            href={link}
            data-content="page_content_link"
          >
            {linkLabel}
          </Button>
        )}
        {!!downloadFile && (
          <Button
            {...btnProps}
            as="a"
            download
            variant={btnProps?.variant}
            className={cx('mt-3', btnProps?.className)}
            target="_blank"
            href={downloadFile.url}
          >
            Download File
          </Button>
        )}
        {hubspotFormID && (
          <div className="mt-5">
            <HubspotFormWithStyle
              theme={hubspotTheme}
              formId={hubspotFormID}
              loading={<div>Loading...</div>}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PageBlockContentBody;
