import DonationCampaignTypes from 'models/donationCampaignTypes';
import { ISalesOrderItem } from 'models/salesOrderItem';

const getMessageActionText = (salesOrderItem?: ISalesOrderItem) => {
  const type = salesOrderItem?.donation_campaign?.donation_campaign_type?.name;
  const heading = salesOrderItem?.donation_option?.heading || '';

  if (type === DonationCampaignTypes.GiftCard) {
    return `gifted a ${heading}`;
  }

  if (type === DonationCampaignTypes.FestiveBauble) {
    return `hung a ${heading} bauble`;
  }

  return 'left a message';
};

export default getMessageActionText;
