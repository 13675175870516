import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';

import captureExceptionForClientSide from 'utils/captureExceptionForClientSide';

import MessageOfSupportForm from 'components/MessageOfSupportForm/MessageOfSupportForm';
import SectionWithContainer, {
  ISectionWithContainerProps,
} from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { useApp } from 'contexts/appContext';
import { useRecaptchaContext } from 'contexts/RecaptchaContext';
import { IPageBlock } from 'models/pageBlock';
import { fetcher } from 'services/httpRequestor';
import { ThankYouMessageService } from 'services/thankYouMessageService';

interface ILeaveFestiveSupportMessageBlockProps
  extends ISectionWithContainerProps {
  fluid?: boolean;
  pageBlock: IPageBlock;
  className?: string;
  noPadding?: boolean;
}
const LeaveFestiveSupportMessageBlock: React.FC<
  ILeaveFestiveSupportMessageBlockProps
> = ({ fluid, pageBlock, ...props }) => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { getRecaptchaToken } = useRecaptchaContext();

  const router = useRouter();
  const { festiveTreeSlug } = useApp();

  const handleSubmit = useCallback(
    async (data: any) => {
      const thankYouMessageService = new ThankYouMessageService(fetcher());
      setLoading(true);
      setSubmitted(false);
      try {
        setError(undefined);
        const recaptchaToken = await getRecaptchaToken();
        await thankYouMessageService.create(data, {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        });
        setSubmitted(true);
        if (festiveTreeSlug) {
          router
            .push(`${festiveTreeSlug}?decoration=message`)
            .then(() => window.scrollTo(0, 0));
        }
      } catch (err: any) {
        setError(err);
        captureExceptionForClientSide(err, {
          extra: {
            data,
          },
        });
        console.error(err);
      }
      setLoading(false);
    },
    [festiveTreeSlug, getRecaptchaToken, router],
  );

  const renderTitle = useCallback(() => {
    return (
      <div className="mb-3">
        <h1 className="mr-2 mb-0 heading heading-danger">
          {pageBlock?.heading}
        </h1>
      </div>
    );
  }, [pageBlock?.heading]);

  return (
    <SectionWithContainer {...props} containerProps={{ fluid }}>
      <div className="d-block d-md-none">{renderTitle()}</div>
      <Row>
        <Col className="mb-3 mb-md-0" md={6}>
          {pageBlock.image && (
            <img
              className="w-100 d-block"
              src={pageBlock.image.url}
              alt={pageBlock.heading}
            />
          )}
        </Col>
        <Col md={6}>
          <div className="d-none d-md-block">{renderTitle()}</div>
          {error && (
            <Alert variant="danger">
              <Alert.Heading>Error</Alert.Heading>
              <p>Something happened in your request</p>
            </Alert>
          )}
          {submitted ? (
            <h3 className="text-primary mb-5">
              Thank you for your message of support!
            </h3>
          ) : (
            <>
              {!!pageBlock?.body && (
                <div
                  className="mb-4"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: pageBlock.body }}
                />
              )}
              <MessageOfSupportForm
                formControlProps={{
                  className: 'form-control-bordered bg-white',
                }}
                loading={loading}
                onSubmit={handleSubmit}
              />
            </>
          )}
        </Col>
      </Row>
    </SectionWithContainer>
  );
};

export default LeaveFestiveSupportMessageBlock;
