import Link from 'next/link';
import React from 'react';
import { Button } from 'react-bootstrap';

import cx from 'classnames';

import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import HalfPageContentWithContainer from 'components/HalfPageContentWithContainer/HalfPageContentWithContainer';
import { IDonationCampaign } from 'models/donationCampaign';

import s from './CurrentAppealsCompactBlock.module.scss';

const SLIDER_SETTINGS = {
  dots: false,
  speed: 500,
  arrows: false,
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

interface IProps {
  donationCampaigns: IDonationCampaign[];
  className?: string;
}
const CurrentAppealsCompactBlock: React.FC<IProps> = ({
  donationCampaigns,
  className,
}) => {
  return (
    <HalfPageContentWithContainer
      className={cx(
        'my-4 my-lg-5 bg-primary text-white overflow-hidden',
        className,
      )}
      halfPageContent={
        <DynamicSlider
          {...SLIDER_SETTINGS}
          className={cx(
            'position-absolute left-0 top-0 right-0 bottom-0',
            s['current-appeals-compact-block-slider'],
          )}
        >
          {donationCampaigns.map((donationCampaign: IDonationCampaign) => (
            <div key={donationCampaign.id}>
              <div
                className="background-cover background-center flex-grow-1"
                style={{
                  backgroundImage: `url(${donationCampaign.mobile_banner_image?.url})`,
                }}
              />
            </div>
          ))}
        </DynamicSlider>
      }
    >
      <div className="py-5 py-lg-6">
        <h2 className="heading heading-secondary">Our Current appeals</h2>
        <p>
          We only exist because of donations from people like you. Through our
          appeals you can help support the vital work of our Lifesavers.
        </p>
        <div className="d-flex flex-column align-items-start">
          {donationCampaigns.map((donationCampaign: IDonationCampaign) => (
            <Link
              key={donationCampaign.id}
              as={`/campaign/${donationCampaign.slug}`}
              href={{
                pathname: '/campaign/[slug]',
                query: { slug: donationCampaign.slug },
              }}
            >
              <Button
                data-content="page_donation_campaign_campaign_name"
                as="a"
                className="my-2"
                variant="danger"
              >
                Visit {donationCampaign.campaign_name}
              </Button>
            </Link>
          ))}
        </div>
      </div>
    </HalfPageContentWithContainer>
  );
};

export default CurrentAppealsCompactBlock;
