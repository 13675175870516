import React from 'react';

import cx from 'classnames';
import renderBlockContentByType from 'utils/renderBlockContentByType';

import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import SectionWithContainer from 'components/PageBlocks/SectionWithContainer/SectionWithContainer';
import { IArticleBlockContent } from 'models/articleBlockContent';
import { IPageBlockContent } from 'models/pageBlockContent';

import s from './VerticalTilesBlock.module.scss';

const SLIDER_SETTINGS = {
  dots: false,
  speed: 500,
  arrows: false,
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 845,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
};

const TOTAL_COLUMNS_BOOTSTRAP = 12;
interface VerticalTilesBlockProps {
  fluid?: boolean;
  blockContents?: IPageBlockContent[] | IArticleBlockContent[];
  className?: string;
}
const VerticalTilesBlock: React.FC<VerticalTilesBlockProps> = ({
  fluid,
  blockContents,
  className,
}) => {
  if (!blockContents?.length) {
    return <h1>There is no block contents</h1>;
  }
  const [firstBlockContent] = blockContents;
  const slidesToShow = Math.floor(
    TOTAL_COLUMNS_BOOTSTRAP / firstBlockContent.column_width,
  );

  return (
    <SectionWithContainer
      className={className}
      noPadding
      containerProps={{ fluid }}
    >
      <DynamicSlider
        {...SLIDER_SETTINGS}
        slidesToShow={slidesToShow}
        className={cx('mx-n3', s.verticalTilesSlider)}
      >
        {blockContents.map(blockContent => (
          <div key={blockContent.id} className="p-2 py-3 d-flex flex-column">
            {renderBlockContentByType(blockContent)}
          </div>
        ))}
      </DynamicSlider>
    </SectionWithContainer>
  );
};

export default VerticalTilesBlock;
