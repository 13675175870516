import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';
import VerticalTile from 'components/VerticalTile/VerticalTile';
import { IStatistic } from 'models/statistic';

const SLIDER_SETTINGS = {
  dots: false,
  speed: 500,
  arrows: false,
  autoplay: true,
  className: 'slider variable-width',
  centerMode: true,
  infinite: false,
  // centerPadding: '60px',
  variableWidth: true,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

interface IProps {
  statistics?: IStatistic[];
  title?: string;
  body?: string;
  fluid?: boolean;
}

const StatisticVerticalTile: React.FC<{ statistic: IStatistic }> = ({
  statistic,
}) => {
  return (
    <VerticalTile
      className="no-shadow bg-light-grey"
      imageURL={statistic.image?.url}
    >
      <h3 className="heading heading-danger">{statistic.heading}</h3>
      {statistic.sub_heading && <p>{statistic.sub_heading}</p>}
    </VerticalTile>
  );
};

const StatisticCardsBlock: React.FC<IProps> = ({
  statistics,
  title,
  body,
  fluid,
  ...props
}) => {
  const renderStatistics = () => {
    if (!statistics?.length) {
      return <h1>There is no statistics</h1>;
    }
    if (statistics.length > 4) {
      return (
        <DynamicSlider {...SLIDER_SETTINGS}>
          {statistics.map((statistic: IStatistic) => (
            <div
              key={statistic.id}
              className="px-2 py-3 d-flex flex-column"
              style={{
                width: '420px',
              }}
            >
              <StatisticVerticalTile key={statistic.id} statistic={statistic} />
            </div>
          ))}
        </DynamicSlider>
      );
    }
    return (
      <Container>
        <Row>
          {statistics.map((statistic: IStatistic) => (
            <Col key={statistic.id} lg={4}>
              <StatisticVerticalTile key={statistic.id} statistic={statistic} />
            </Col>
          ))}
        </Row>
      </Container>
    );
  };

  return (
    <section {...props}>
      <Container className="mb-2" fluid={fluid}>
        {title && (
          <RowWithOffsetCol className="mb-5">
            <h2 className="heading heading-danger">{title}</h2>
          </RowWithOffsetCol>
        )}
        {body && (
          <RowWithOffsetCol>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </RowWithOffsetCol>
        )}
      </Container>
      {renderStatistics()}
    </section>
  );
};

export default StatisticCardsBlock;
